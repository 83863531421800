const initState = {
    open: false,
    attributes: [
        {
            attr: "OKRES",
            alias: "Okres",
            format: (value) => value
        },
        {
            attr: "OBEC",
            alias: "Obec",
            format: (value) => value
        },        
        {
            attr: "podiel_okr",
            alias: "Podiel rómskych komunít na celkovom obyvateľstve okresov Slovenskej republiky",
            format: (value) => `${value}%`
        },
        {
            attr: "podiel_r_3",
            alias: "Podiel rómskych komunít na celkovom obyvateľstve obcí Atlasu rómskych komunít",
            format: (value) => `${value}%`
        },
        {
            attr: "jazykova_3",
            alias: "Obce podľa prevažne používaného jazyka rómskej komunity",
            format: (value) => value
        },
        {
            attr: "vyrazne_2",
            alias: "Obce s výraznými rozdielmi v miere pripojenia na elektrickú sieť medzi RK a ostatnými obyvateľmi",
            format: (value) => value
        },
        {
            attr: "vyrazne_5",
            alias: "Obce s výraznými rozdielmi v dostupnosti kanalizácie medzi RK a ostatnými obyvateľmi",
            format: (value) => value
        },
        {
            attr: "vyrazne_8",
            alias: "Obce s výraznými rozdielmi v dostupnosti plynu medzi RK a ostatnými obyvateľm",
            format: (value) => value
        },
        {
            attr: "vyrazne_11",
            alias: "Obce s výraznými rozdielmi v dostupnosti vodovodu medzi RK a ostatnými obyvateľmi",
            format: (value) => value
        },
        {
            attr: "pokrytie14",
            alias: "Obce s prítomnosťou terénnej sociálnej práce",
            format: (value) => value
        },
        {
            attr: "pokrytie_6",
            alias: "Obce s prítomnosťou komunitného centra",
            format: (value) => value
        },
        {
            attr: "pokrytie18",
            alias: "Obce s prítomnosťou asistentov osvety zdravia",
            format: (value) => value
        },
        {
            attr: "pokrytie10",
            alias: "Obce s prítomnosťou miestnej občianskej poriadkovej služby",
            format: (value) => value
        },
        {
            attr: "pokrytie_3",
            alias: "Obce podľa miery prítomnosti pomocných profesií",
            format: (value) => value
        },
        {
            attr: "romovia_3",
            alias: "Obce s rómskymi poslancami miestneho zastupiteľstva",
            format: (value) => value
        },
        {
            attr: "pastorac_3",
            alias: "Obce s pastoračnou činnosťou v RK podľa cirkví",
            format: (value) => value
        },
        /**
         * Obce 
         */
        {
            attr: "nm4",
            alias: "Obec",
            format: (value) => value
        },
        {
            attr: "water_cover",
            alias: "Prístup k pitnej vode",
            format: (value) => value !== null && `${Math.round(100 * value, 2)}%`
        },
        {
            attr: "sewerage_cover",
            alias: "Prístup ku kanalizácii",
            format: (value) => value !== null && `${Math.round(100 * value, 2)}%`
        },
        {
            attr: "romani_population_rate",
            alias: "Podiel rómskeho obyvateľstva",
            format: (value) => value !== null && `${Math.round(100 * value, 2)}%`
        },
        
        /**
         * Koncentracie
         */
        {
            attr: "kod_Okres",
            alias: "Okres",
            format: (value) => value
        },
        {
            attr: "kod_Obec",
            alias: "Obec",
            format: (value) => value
        },
        {
            attr: "kod_Názov",
            alias: "Názov",
            format: (value) => value
        },
        {
            attr: "typ",
            alias: "Koncentrácie podľa ich typu",
            format: (value) => value
        },
        {
            attr: "podiel",
            alias: "Koncentrácie podľa podielu subštandardných obydlí",
            format: (value) => `${value}%`
        },
        {
            attr: "pod cislo",
            alias: "Koncentrácie podľa priemerného počtu ľudí na jedno obydlie",
            format: (value) => value
        },
        {
            attr: "vysporiad",
            alias: "Koncentrácie podľa podielu vysporiadaných pozemkov",
            format: (value) => `${value}%`
        },
        {
            attr: "vodovod",
            alias: "Koncentrácie podľa miery využívania vodovodu",
            format: (value) => `${value}%`
        },
        {
            attr: "kanal",
            alias: "Koncentrácie podľa miery využívania kanalizácie",
            format: (value) => `${value}%`
        },
        {
            attr: "elektrik",
            alias: "Koncentrácie podľa miery pripojenia na elektrickú sieť",
            format: (value) => `${value}%`
        },
        {
            attr: "VO",
            alias: "Koncentrácie podľa miery pokrytia verejným osvetlením",
            format: (value) => `${value}%`
        },
        {
            attr: "plyn",
            alias: "Koncentrácie podľa miery využívania plynu",
            format: (value) => `${value}%`
        },
        {
            attr: "kurenie",
            alias: "Koncentrácie podľa miery vykurovania centrálnym zásobovaním tepla, plynom alebo elektrinou",
            format: (value) => `${value}%`
        },
        {
            attr: "Bob_Kuk",
            alias: "Koncentrácie podľa miery pokrytia kuka alebo bobor nádobami",
            format: (value) => `${value}%`
        },
        {
            attr: "povodne",
            alias: "Koncentrácie podľa výskytu záplav v posledných 20 rokoch",
            format: (value) => value
        },
        {
            attr: "zeleznica",
            alias: "Vzdialenosť koncentrácií k najbližšej zastávke železnice",
            format: (value) => `${value} km`
        },
        {
            attr: "lekar",
            alias: "Vzdialenosť koncentrácií k najbližšej ambulancii všeobecného lekára",
            format: (value) => `${value} km`
        },
        {
            attr: "MŠ",
            alias: "Vzdialenosť koncentrácií k najbližšej navštevovanej materskej škole",
            format: (value) => `${value} km`
        },
        {
            attr: "1-4",
            alias: "Vzdialenosť koncentrácií k najbližšej navštevovanej základnej škole 1-4",
            format: (value) => `${value} km`
        },
        {
            attr: "1-9",
            alias: "Vzdialenosť koncentrácií k najbližšej navštevovanej základnej škole 1-9",
            format: (value) => `${value} km`
        }
    ],
    results: []
}

const identification = (state = initState, action) => {
    switch (action.type) {
        case 'SET_IDENTIFICATION':
            return {
                ...state,
                ...action.payload
            }
        case 'ADD_IDENTIFICATION_RESULT':
            return {
                ...state,
                open: true,
                results: [...state.results, action.result]
            }
        case 'CLEAR_IDENTIFICATION':
            return initState
        default:
            return state
    }
}

export default identification;