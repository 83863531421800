import React from "react"
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import MapProvider from './MapProvider'
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import rootReducer from '../store/root';
import GlobalCss from "./GlobalCss";

const store = createStore(rootReducer)

const Providers = ({
    children,
    theme
}) => {

    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <GlobalCss/>
                <CssBaseline/>
                <MapProvider>
                    {children}
                </MapProvider> 
            </ThemeProvider>
        </Provider>
    )
}

export default Providers;