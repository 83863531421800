import { 
    ListItem, 
    ListItemIcon, 
    ListItemText, 
    ListItemSecondaryAction, 
    Switch, 
    Checkbox
} from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles";
import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from "lodash/isEmpty"
import Legend from "./Legend";
import Filter from "./Filter";

const LayerItem = ({
    title,
    onChange,
    id,
    classes,
    active,
    filter,
    legend,
    checkbox,
    icon
}) => {
    return (
        <>
            <ListItem classes={{root: classes.listItem}} button onClick={() => onChange(id)} dense={true}>
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                <ListItemText                              
                    primary={title} 
                    style={{paddingRight: 24}}
                />
                <ListItemSecondaryAction>
                    {
                        checkbox && <Checkbox onClick={() => onChange(id)} classes={{root: classes.checkbox}}  checked={active} />
                    }
                    {
                        !checkbox && <Switch
                            edge="end"
                            onClick={() => onChange(id)}
                            checked={active}
                        />
                    }
                </ListItemSecondaryAction>
            </ListItem> 
            {!isEmpty(filter) &&
                <Filter 
                    colapsed={active}
                    id={id}
                    {...filter}
                />
            }   
            {!isEmpty(legend) &&
                <Legend 
                    {...legend}
                    colapsed={active} 
                />
            }  
        </>
    )
}

LayerItem.defaultProps = {
    legend: {},
    active: false
}

LayerItem.propTypes = {
    title: PropTypes.string,
    onChange: PropTypes.func,
    id: PropTypes.string,
    active: PropTypes.bool,
    lenged: PropTypes.object,
    classes: PropTypes.object,
    icon: PropTypes.element
}

const styles = (theme) => ({
    listItem: {
        
    },
    checkbox: {
        position: "relative",
        right: -10
    }
})

export default withStyles(styles)(LayerItem)