
export const getMapMarker = ({
    size = 50,
    color = "#ff0000",
    stroke = "#ffffff",
    text= ""
}) => (   
    `<svg
        xmlns:dc="http://purl.org/dc/elements/1.1/"
        xmlns:cc="http://creativecommons.org/ns#"
        xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
        xmlns:svg="http://www.w3.org/2000/svg"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
        xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
        width="${size * 1.5}"
        height="${size}"
        version="1.1"
        class=""
        aria-hidden="true"
        focusable="false"
        style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 38.4 24"
        id="svg4"
        sodipodi:docname="test.svg"
        inkscape:version="1.0.2 (e86c870879, 2021-01-15, custom)">
        <metadata id="metadata10">
        <rdf:RDF>
            <cc:Work
                rdf:about="">
            <dc:format>image/svg+xml</dc:format>
            <dc:type
                rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
            </cc:Work>
        </rdf:RDF>
        </metadata>
        <defs id="defs8" />
        <sodipodi:namedview
            pagecolor="#ffffff"
            bordercolor="#666666"
            borderopacity="1"
            objecttolerance="10"
            gridtolerance="10"
            guidetolerance="10"
            inkscape:pageopacity="0"
            inkscape:pageshadow="2"
            inkscape:window-width="1920"
            inkscape:window-height="986"
            id="namedview6"
            showgrid="false"
            inkscape:zoom="8"
            inkscape:cx="28.062764"
            inkscape:cy="21.160633"
            inkscape:window-x="-11"
            inkscape:window-y="-11"
            inkscape:window-maximized="1"
            inkscape:current-layer="svg4"
            inkscape:document-rotation="0" />
            
        <path
            d="m 2.6888529,3.4837596 32.7034701,-0.06 c 2.038168,-0.00374 2.070436,0.9556293 2.070436,1.9864303 V 16.608771 c 0,1.030801 -0.09268,2.087455 -2.130436,2.04643 H 21.951458 l -2.969362,4.397125 -3.212375,-4.457125 -13.0808681,-0.12 C 0.65076816,18.456504 0.47717727,17.685419 0.49841882,16.654837 l 0.06,-2.911036 V 5.4101899 c 0,-1.030801 0.092267,-1.9226909 2.13043408,-1.9264303 z"
            fill="${color}"
            id="path2"
            style="stroke:${stroke}" 
        />
            <text 
                font-size="10px" 
                font-family="calibri" 
                fill="#fff" 
                x="50%" 
                y="52%" 
                dominant-baseline="middle" 
                text-anchor="middle"
            >
                ${text}
            </text>
        </svg>`

)

// size = 5,
// color = "#ff0000",
// strokeColor = "#353535",
// strokeWidth = 0

export const getRadiusSymbol = ({
    size,
    color,
    strokeColor,
    strokeWidth
}) => {
    const halfSize = size / 2;
    return `
        <svg xmlns="http://www.w3.org/2000/svg" height="${size}" width="${size}">
            <circle cx="${halfSize}px" cy="${halfSize}px" r="${halfSize - strokeWidth}px" style="stroke:${strokeColor};stroke-opacity:1;stroke-width:${strokeWidth};" fill="${color}" />
        </svg>
    `
}