import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import { makeStyles, CardHeader } from '@material-ui/core'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';

const useStyles = makeStyles(theme => ({
    root: {

    },
    table: {

    }
}))

const InfoDialog = () => {

    const { searchConfig: {
        open,
        title,
        population,
        romaniPopulationTotal,
        romaniPopulation,
        projects,
        constructions
    } } = useSelector(state => state.municipalityLayer)
    const dispatch = useDispatch()
    const classes = useStyles()

    const closeDialog = () => {
        dispatch({type: "CLOSE_SEARCH_DIALOG"})
    }

    return (
        <Dialog onClose={closeDialog} aria-labelledby="simple-dialog-title" open={open} >
            <TableContainer component={Card}>
                <CardHeader title={title}/>
                {(population || romaniPopulation || romaniPopulationTotal || projects || constructions) &&
                <Table className={classes.table} aria-label="simple table">
                    <TableBody>
                        {population && 
                            <TableRow>
                            <TableCell component="th" scope="row">
                            Počet obyvateľov
                            </TableCell>
                            <TableCell align="right">{population}</TableCell>
                            </TableRow>
                        }
                        {romaniPopulation &&
                            <TableRow>
                            <TableCell component="th" scope="row">
                            Podiel rómskych obyvateľov
                            </TableCell>
                                <TableCell align="right">{Math.round(romaniPopulation * 100, 2)}%</TableCell>
                            </TableRow>
                        }
                        {romaniPopulationTotal &&
                            <TableRow>
                            <TableCell component="th" scope="row">
                            Počet rómskych obyvateľov
                            </TableCell>
                                <TableCell align="right">{romaniPopulationTotal}</TableCell>
                            </TableRow>
                        }
                        {projects &&
                            <TableRow>
                                <TableCell component="th" scope="row">
                                Projekty v obci
                                </TableCell>
                                <TableCell align="right">{projects}</TableCell>
                            </TableRow>
                        }
                        {constructions &&
                            <TableRow>
                                <TableCell component="th" scope="row">
                                Výstavba v obci
                                </TableCell>
                                <TableCell align="right">{constructions}</TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
                }
            </TableContainer>
        </Dialog>
    )

}

export default InfoDialog