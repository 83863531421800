import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import MunicipalityLayer from './components/MunicipalityLayer';
import * as serviceWorker from './serviceWorker';
import "./app.css"

ReactDOM.render( 
    <App
        beforeLayers={[
            <MunicipalityLayer url="/data/obec.geojson" />
        ]}
    />, 
    document.getElementById('root')
);

serviceWorker.unregister();
