import React, { useEffect, useState } from 'react';
import ol_Map from 'ol/Map';
import ol_View from 'ol/View';
import { transform } from 'ol/proj';
import {register} from 'ol/proj/proj4';
import proj4 from 'proj4';
// import { defaults as defaultInteractions } from 'ol/interaction';
// import {CustomPointer} from './interaction/PointerInteraction'
import { useSelector } from 'react-redux';

export const MapContext = React.createContext({});

const MapProvider = ({
    children
}) => {

  const [map, setMap] = useState();
  const view = useSelector(state => state.map.view);

  // Register S-JTSK
  proj4.defs("EPSG:5514","+proj=krovak +lat_0=49.5 +lon_0=24.83333333333333 +alpha=30.28813972222222 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=589,76,480,0,0,0,0 +units=m +no_defs");
  register(proj4);

  useEffect(() => {
 
    const mapCenter = transform(view.center, 'EPSG:4326', view.projection);
    const m = new ol_Map({
      // interactions: defaultInteractions().extend([new CustomPointer()]),
      controls: [],
      keyboardEventTarget: document,
      view: new ol_View({
        center: mapCenter,
        zoom: view.zoom,
        projection: view.projection,
        minZoom: view.minZoom,
        maxZoom: view.maxZoom
      })
    });
   
    setMap(m)

    return () => {
      m.setTarget(null)
    }
    
  }, [view])

 

  return map ?
    <MapContext.Provider value={map}>
      {children}
    </MapContext.Provider> : null

}

MapProvider.defaultProps = {
  projection: "EPSG:3857"
}

export default MapProvider