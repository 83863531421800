import React, {Fragment} from 'react';
import {withStyles, Drawer, IconButton, Typography, Divider, useTheme, useMediaQuery} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { useSelector, useDispatch } from 'react-redux';

const styles = (theme) => ({
   root: {

   },
   drawerHeader: {
        display: "flex",
        alignItems: "center",
        paddingLeft: theme.spacing(2),
        color: theme.palette.primary.contrastText,
        justifyContent: "space-between",
        background: theme.palette.primary.main
   },
   heading: {
        fontSize: "1.4em"
   },
   button: {
       float: "right",
       color: "#fff"
   }
})


const SideNav = ({
    classes,
    children
}) => {

    const open = useSelector(state => state.general.showMenu)
    const dispatch = useDispatch();
    const theme = useTheme()
    const match = useMediaQuery(theme.breakpoints.down('sm'))

    const toggleDrawer = () => {
        dispatch({type: 'SHOW_MENU', payload: !open})
    }

    return (
        <Fragment>
            <Drawer
                variant={match ? "temporary" : "persistent"}
                open={open}
                onClose={toggleDrawer}
            >
                <div className={classes.drawerHeader}>
                    <Typography className={classes.heading}>
                        Atlas rómskych komunít 2019
                    </Typography>
                    <IconButton className={classes.button} onClick={toggleDrawer}>
                        {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </div>
                <Divider />
                {children}
            </Drawer>
        </Fragment>
    )
}


SideNav.propTypes = {
   
}

SideNav.defaultProps = {
   
}

export default withStyles(styles)( SideNav )