import React from "react"
import {
    makeStyles,
    Collapse, 
    Paper, 
    Typography
} from "@material-ui/core"

const Legend = ({
    colapsed,
    values,
    format
}) => {

    const classes = useStyles()

    return (
        <Collapse in={colapsed}>
            <Paper classes={{root: classes.legend}}>
            {
                values.slice(0).reverse().map((g, idx) => (
                    <div key={idx} className={classes.legendItem}>
                        <div className={classes.legendBox} style={{background: g.color}}></div>
                        <Typography className={classes.legendText}>
                            {format(g.value)}
                        </Typography>
                    </div>
                ))
            }
            </Paper>
        </Collapse>
    )
}

Legend.defaultProps = {
    values: [],
    format: (value) => value
}

const useStyles = makeStyles((theme) => ({
    legend: {
        marginBottom: 8,
        marginLeft: 16,
        marginRight: 16,
        padding: 8
    },
    legendBox: {
        width: 30,
        height: 20
    },
    legendItem: {
        display: "flex",
        alignItems: "center"
    },
    legendText: {
        paddingLeft: 4,
        fontSize: "0.9em"
    }
}))

export default Legend