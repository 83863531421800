import { 
    MUNICIPALITY_ATTR_WATER_GRADIENT, 
    MUNICIPALITY_ATTR_SEWERAGE_GRADIENT, 
    MUNICIPALITY_ATTR_TSP, 
    MUNICIPALITY_ATTR_KC, 
    MUNICIPALITY_ATTR_MOPS, 
    MUNICIPALITY_ATTR_ASSISTENTS, 
    MUNICIPALITY_ATTR_ZK,
    MUNICIPALITY_ATTR_MS_CONSTRUCTIONS, 
    MUNICIPALITY_ATTR_KC_CONSTRUCTIONS, 
    MUNICIPALITY_ATTR_WATER, 
    MUNICIPALITY_ATTR_SEWERAGE, 
    MUNICIPALITY_ATTR_ROMANI_POPULATION,
    GRADIENT_WATER,
    GRADIENT_SEWERAGE,
    GRADIENT_POPULATION
} from "../constants"

const initState = {
    visible: true,
    search: "",
    searchResults: [],
    activeFilter: false,
    mode: "filter",
    gradientGroup: "Obce", 
    searchConfig: {
        open: false,
        title: "",
        population: "",
        romaniPopulation: "",
        romaniPopulationTotal: "",
        projects: "",
        constructions: ""
    },
    gradients: [
        {
            id: "water",
            title: "Prístup k pitnej vode",
            active: false,
            legend: {
                values: GRADIENT_WATER,
                format: (value) => `${(100 * value)}%`,
            },
            attr: MUNICIPALITY_ATTR_WATER_GRADIENT,
            style: GRADIENT_WATER
        },
        {
            id: "sewerage",
            title: "Prístup ku kanalizácii",
            active: false,
            legend: {
                values: GRADIENT_SEWERAGE,
                format: (value) => `${(100 * value)}%`,
            },
            attr: MUNICIPALITY_ATTR_SEWERAGE_GRADIENT,
            style: GRADIENT_SEWERAGE
        },
        {
            id: "romaniPopulation",
            title: "Podiel rómskeho obyvateľstva",
            active: false,
            legend: {
                values: GRADIENT_POPULATION,
                format: (value) => `${(100 * value)}%`,
            },
            attr: MUNICIPALITY_ATTR_ROMANI_POPULATION,
            style: GRADIENT_POPULATION
        }
    ],
    filter: [
        {
            id: "tsp",
            title: "TSP",
            active: false,
            attr: MUNICIPALITY_ATTR_TSP,
            value: 'nie'
        },
        {
            id: "kc",
            title: "KC",
            active: false,
            attr: MUNICIPALITY_ATTR_KC,
            value: 'nie'
        },
        {
            id: "mops",
            title: "MOPS",
            active: false,
            attr: MUNICIPALITY_ATTR_MOPS,
            value: 'nie'
        },
        {
            id: "asistent",
            title: "Asistenti",
            active: false,
            attr: MUNICIPALITY_ATTR_ASSISTENTS,
            value: 'nie'
        },
        {
            id: "zk",
            title: "ZK",
            active: false,
            attr: MUNICIPALITY_ATTR_ZK,
            value: 'nie'
        },
        {
            id: "msConstruction",
            title: "Budovanie MŠ",
            active: false,
            attr: MUNICIPALITY_ATTR_MS_CONSTRUCTIONS,
            value: 'nie'
        },
        {
            id: "kcConstruction",
            title: "Budovanie KC",
            active: false,
            attr: MUNICIPALITY_ATTR_KC_CONSTRUCTIONS,
            value: 'nie'
        },
        {
            id: "water",
            title: "Pitná voda",
            active: false,
            attr: MUNICIPALITY_ATTR_WATER,
            value: 'nie'
        },
        {
            id: "sewerage",
            title: "Odpadové hospodárstvo",
            active: false,
            attr: MUNICIPALITY_ATTR_SEWERAGE,
            value: 'nie'
        }
    ]
    
}

const adaptString = (str) => {
    return str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
}

const municipalityLayer = (state = initState, action) => {
    switch (action.type) {
        case 'SEARCH':
            return {
                ...state,
                search: adaptString(action.search)
            }
        case 'SET_SEARCH_RESULTS':
            return {
                ...state,
                searchResults: action.results
            }
        case 'SET_MUNICIPALITY_VISIBLE':
            return {
                ...state,
                visible: action.visible
            }
        case 'SET_ACTIVE_FITLER':
            return {
                ...state,
                activeFilter: action.visible
            }
        case 'CLOSE_SEARCH_DIALOG':
            return {
                ...state,
                searchConfig: { ...state.searchConfig, open: false}
            }
        case 'SET_SEARCH':
            return {
                ...state,
                searchConfig: { ...state.searchConfig, ...action }
            }
        case 'SET_ACTIVE_GRADIENT': 
            const gradients = state.gradients.map(g => ({
                ...g,
                active: action.id === g.id ? !g.active : false
            }))
            
            const isAnyActiveGradient = gradients.find(g => g.active === true);

            return {
                ...state,
                gradients,
                activeFilter: false,
                mode: isAnyActiveGradient ? "gradient" : "filter",
                filter: state.filter.map(f => ({
                    ...f,
                    active: false
                })),
            }
        case 'ACTIVE_MUNICIPALITY_FILTER':
            return {
                ...state,
                activeFilter: true,
                filter: state.filter.map(f => ({
                    ...f,
                    active: f.id === action.id ? action.active : f.active
                })),
                mode: "filter",
                gradients: state.gradients.map(g => ({...g, active: false}))
            }
        default:
            return state
    }
}

export default municipalityLayer;