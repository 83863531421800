import { useEffect, useMemo, useCallback } from "react"
import ol_vector_Source from "ol/source/Vector"
import ol_format_GeoJSON from "ol/format/GeoJSON"
import ol_layer_VectorImage from 'ol/layer/VectorImage';
import { useMap } from "../hooks/useMap";
import { getDefaultStyle } from "./styles";
import isEmpty from "lodash/isEmpty";

const findGradientColor = (gradient, value) => {
    const pair = gradient.find(x => value <= x.value);
    return pair && pair.color
}

const findCategoriesColor = (categories, value) => {
    const pair = categories.find(x => value === x.value);
    return pair && pair.color
}

// const normalizeBetweenTwoRanges = (val, minVal, maxVal, newMin, newMax) => {
//     return newMin + (val - minVal) * (newMax - newMin) / (maxVal - minVal);
// };

const getStyle = (feature, style, filter, value) => {
    if(style.fill.gradient){
        const gradientAttr = feature.get(style.fill.gradient.attribute)
        const c = findGradientColor(style.fill.gradient.colors, gradientAttr)
        style.fill.color = c;
        // style.stroke.color = c;
        // if(filter.to === filter.min){
        //     style.point.radius = 2;
        // } else if(filter.from === filter.max){
        //     style.point.radius = 8;
        // } else {
        //     style.point.radius = normalizeBetweenTwoRanges(value, filter.from, filter.to, 2, 8);
        // }
        return getDefaultStyle(style)
    } else{
        return getDefaultStyle(style)
    }
}

const getFilterStyle = ({feature, filter, style}) => {
    const attrValue = feature.get(filter.attribute);
    if(filter.type === "<>" && attrValue <= filter.to && attrValue >= filter.from){
        return getStyle(feature, style, filter, attrValue)
    }
    if(filter.type === "=" && attrValue === filter.value){
        return getDefaultStyle(style)
    }
    return null;
}

const GeoJSONLayer = ({
    url,
    visible,
    zIndex,
    style,
    title,
    identificationTitle,
    filter
}) => {
    
    const map = useMap()
    const handleStyle = useCallback((feature) => {
        if(!isEmpty(filter)){
            return getFilterStyle({feature, filter, style})
        }

        if(style.fill && style.fill.gradient){
            style.fill.color = findGradientColor(style.fill.gradient.colors, style.fill.gradient.attribute)
        }

        if(style.fill && style.fill.categories){
            style.fill.color = findCategoriesColor(style.fill.categories.colors, feature.get(style.fill.categories.attribute))
        }

        return getDefaultStyle(style)
    }, [filter, style])

    const layer = useMemo(() => new ol_layer_VectorImage({
        imageRatio: 2,
        visible,
        title,
        identificationTitle,
        zIndex,
        source: new ol_vector_Source({
            url,
            format: new ol_format_GeoJSON()
        }),
        style: handleStyle
    }), [handleStyle, identificationTitle, title, url, visible, zIndex])
    
     

    useEffect(() => {
        layer && layer.setVisible(visible)
    }, [layer, visible])

    useEffect(() => {
        map.addLayer(layer)
        return () => {
            map.removeLayer(layer)
        }
    }, [map, layer])
    
    return null
}

GeoJSONLayer.defaultStyle = {
    filter: {}
}

export default GeoJSONLayer