import React from 'react';
import OSMLayer from './OsmLayer';
import { useSelector } from 'react-redux';
import TileWMS from './TileWMS';
import GeoJSONLayer from './GeoJSONLayer';

const LayersLoader = () => {

  const layers = useSelector(state => state.map.layers)
  const baseLayers = useSelector(state => state.map.baseLayers)
  const interactiveLayers = useSelector(state => state.map.interactiveLayers)

  const base = baseLayers.map(layer => {
    if(layer.type.toLowerCase() === "osm")
      return <OSMLayer key={layer.id} {...layer} />
    if(layer.type.toLowerCase() === "tilewms")
      return <TileWMS key={layer.id} {...layer} />
    
    return null;
    
  })

  const commonLayers = layers.map(layer => {
    if(layer.type.toLowerCase() === "osm")
      return <OSMLayer key={layer.id} {...layer} />
    if(layer.type.toLowerCase() === "tilewms")
      return <TileWMS key={layer.id} {...layer} />
    
    return null;
  })

  const interactive = interactiveLayers.map(layer => {
    return ( <GeoJSONLayer {...layer} />
    )
  })

  return (
    <>
      {base} {commonLayers} {interactive}
    </>
  )
        
}

export default LayersLoader;
