import { useSelector, useDispatch } from "react-redux"
import List from '@material-ui/core/List';
// import FilterIcon from '@material-ui/icons/FilterList';
import LayerIcon from '@material-ui/icons/LayersOutlined';
import React from 'react'
import { Typography, makeStyles, Icon, Divider } from "@material-ui/core";
import LayerItem from "./LayerItem";
import isEmpty from "lodash/isEmpty"
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import FilterIconOutlined from '@material-ui/icons/FilterListOutlined';

const useStyles = makeStyles((theme) => ({
    accordionDetail: {
        padding: 0,
        width: "100%"
    },  
    root: {
        width: "100%",
        maxWidth: 380,
        [theme.breakpoints.down("md")]: {
            maxWidth: 360
        },
        [theme.breakpoints.down("xs")]: {
            maxWidth: 340
        }
    },
    title: {
        paddingLeft: 16,
        color: theme.palette.grey[800]
    }
}))

const MunicipalitiesLayerSwitcher = () => {

    const { 
        //filter, 
        gradients, 
        //activeFilter, 
        gradientGroup 
    } = useSelector(state => state.municipalityLayer)
    const layers = useSelector(state => state.map.layers)
    const interactiveLayers = useSelector(state => state.map.interactiveLayers)
    const dispatch = useDispatch()
    const classes = useStyles()

    const interactiveLayesGroups = interactiveLayers.map(x => x.group).filter((item, pos, self) => {
        return self.indexOf(item) === pos && item;
    })

    const setLayerVisibility = (id, visible, type) => {
        dispatch({type: "SET_LAYER_VISIBILITY", id, visible, layerType: type})
    }

    // const setFilter = (id, active) => {
    //     dispatch({type: "ACTIVE_MUNICIPALITY_FILTER", id, active})
    // }

    const setActiveGradietLayer = (id) => {
        dispatch({type: "SET_ACTIVE_GRADIENT", id})
    }

    // const setActiveFilterLayer = (visible) => {
    //     dispatch({type: "SET_ACTIVE_FITLER", visible})
    // }

    return (
        <div className={classes.root}>  
            { !isEmpty(layers) &&
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Icon><LayerIcon/></Icon>
                        <Typography className={classes.title}>Podkladové vrstvy</Typography>
                    </AccordionSummary>
                    <AccordionDetails classes={{root: classes.accordionDetail}}>
                        <List className={classes.root}>
                        {
                            layers.map((layer) => (      
                                <LayerItem
                                    key={layer.id}
                                    icon={<LayerIcon/>}
                                    id={layer.id}
                                    legend={layer.legend}
                                    title={layer.title}
                                    onChange={(id) => setLayerVisibility(id, !layer.visible)}
                                    active={layer.active}
                                />                                                            
                            ))
                        }
                        </List>
                    </AccordionDetails>
                </Accordion>
            }
            
           
            { (!isEmpty(interactiveLayers)) &&
                interactiveLayesGroups.map((group, idx) => (
                    <Accordion key={idx}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Icon><LayerIcon/></Icon>
                        <Typography className={classes.title}>{group}</Typography>
                    </AccordionSummary>
                    <AccordionDetails classes={{root: classes.accordionDetail}}>
                        <List className={classes.root}>
                            {
                                interactiveLayers.filter(x => x.displayInLayerTree && x.group === group).map((layer) => (      
                                    <LayerItem
                                        key={layer.id}
                                        icon={<LayerIcon/>}
                                        id={layer.id}
                                        title={layer.title}
                                        filter={layer.filter}
                                        checkbox
                                        legend={layer.legend}
                                        onChange={(id) => setLayerVisibility(id, !layer.visible, "interactive")}
                                        active={layer.visible}
                                    />                                                            
                                ))
                            }
                            {
                                (group === gradientGroup) && (
                                    <>
                                    <Divider style={{marginTop: 12, marginBottom: 12}}/>
                                    {
                                        gradients.map((gradient) => (
                                            <LayerItem
                                                icon={<LayerIcon/>}
                                                id={gradient.id}
                                                checkbox
                                                key={gradient.id}
                                                title={gradient.title}
                                                legend={gradient.legend}
                                                onChange={setActiveGradietLayer}
                                                active={gradient.active}
                                                legendItems={gradient.style}
                                            />
                                        ))
                                    }
                                </>
                                )
                            }
                        </List>
                    </AccordionDetails>
                </Accordion>
                ))
                
            }

            {/* { (!isEmpty(interactiveLayers)) &&
                    <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Icon><LayerIcon/></Icon>
                        <Typography className={classes.title}>Ostatné vrstvy</Typography>
                    </AccordionSummary>
                    <AccordionDetails classes={{root: classes.accordionDetail}}>
                        <List className={classes.root}>
                            {
                                interactiveLayers.filter(x => x.displayInLayerTree && !x.group).map((layer) => (      
                                    <LayerItem
                                        key={layer.id}
                                        icon={<LayerIcon/>}
                                        id={layer.id}
                                        title={layer.title}
                                        filter={layer.filter}
                                        legend={layer.legend}
                                        onChange={(id) => setLayerVisibility(id, !layer.visible, "interactive")}
                                        active={layer.visible}
                                    />                                                            
                                ))
                            }
                        </List>
                    </AccordionDetails>
                </Accordion>
                
            } */}

            {/* { (!isEmpty(gradients)) &&
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Icon><LayerIcon/></Icon>
                        <Typography className={classes.title}>Ostatné mapové vrstvy</Typography>
                    </AccordionSummary>
                    <AccordionDetails classes={{root: classes.accordionDetail}}>
                        <List className={classes.root}>
                            {
                                gradients.map((gradient) => (
                                    <LayerItem
                                        icon={<LayerIcon/>}
                                        id={gradient.id}
                                        checkbox
                                        key={gradient.id}
                                        title={gradient.title}
                                        legend={gradient.legend}
                                        onChange={setActiveGradietLayer}
                                        active={gradient.active}
                                        legendItems={gradient.style}
                                    />
                                ))
                            }
                        </List>
                    </AccordionDetails>
                </Accordion>
            } */}

            {/* { !isEmpty(filter) &&
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Icon>
                            <FilterIconOutlined/>
                        </Icon>
                        <Typography className={classes.title}>Projekty v obciach (Filter)</Typography>
                    </AccordionSummary>
                    <AccordionDetails classes={{root: classes.accordionDetail}}>
                        <div style={{width: "100%"}}>
                        <Box paddingRight={1} width={'100%'} display="flex" alignItems="center" justifyContent="space-between">
                            <Typography className={classes.title}>Zobraziť vrstvu projektov</Typography>
                            <Checkbox checked={activeFilter} onChange={(e) => setActiveFilterLayer(e.target.checked)} />
                        </Box>
                        <List className={classes.root}>
                            
                            {
                                filter.map((f => ( 
                                    <LayerItem
                                        icon={<FilterIcon/>}
                                        id={f.id}
                                        key={f.id}
                                        legend={f.legend}
                                        title={f.title}
                                        onChange={(id) => setFilter(id, !f.active)}
                                        active={f.active}
                                    />                                                                 
                                )))
                            } 
                        </List>
                        </div>
                    </AccordionDetails>
                </Accordion>
            } */}
        </div>
    )
}

export default MunicipalitiesLayerSwitcher