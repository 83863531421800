import React from 'react';
import {withStyles} from '@material-ui/core';
import SideNav from './SideNav'
import LayerSwitcher from './LayerSwitcher';
import Fab from '@material-ui/core/Fab'
import MenuIcon from '@material-ui/icons/Menu'
import { useDispatch } from 'react-redux';
import InfoDialog from './InfoDialog'

const styles = (theme) => ({
    root: {
        height: "100%",
        width: "100%",
    },
    menuButton: {
        position: "absolute",
        top: 16,
        left: 16,
        zIndex: 1,
        cursor: "pointer",
        [theme.breakpoints.down("sm")]: {
            left: 16,
            width: 50,
            height: 50,
            borderRadius: 4,
            boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
        }
    },
    map: {
        position: "absolute",
        top: 0,
        bottom: 0,
        width: "100%"
    }
})

const Layout = ({ 
    children, 
    classes,
}) => {

    const dispatch = useDispatch()

    const openDrawer = () => {
        dispatch({type: 'SHOW_MENU', payload: true})
    }

    return (
        <div className={classes.root}>
            
            <div className={classes.map}>
                {children}
            </div>

            <Fab 
                onClick={openDrawer} 
                className={classes.menuButton} 
                color="primary" 
                aria-label="Menu"
            >
                <MenuIcon/>
            </Fab>

            <SideNav>
                <LayerSwitcher/>
            </SideNav>

            <InfoDialog/>
        </div>
    )
}

Layout.propTypes = {
   
}

Layout.defaultProps = {
   
}

export default withStyles(styles)( Layout )