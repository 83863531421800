export const MUNICIPALITY_ATTR_TSP = "tsp";
export const MUNICIPALITY_ATTR_KC = "kc";
export const MUNICIPALITY_ATTR_MOPS = "mops";
export const MUNICIPALITY_ATTR_ASSISTENTS = "assistants";
export const MUNICIPALITY_ATTR_ZK = "zk";
export const MUNICIPALITY_ATTR_MS_CONSTRUCTIONS = "ms_construction";
export const MUNICIPALITY_ATTR_KC_CONSTRUCTIONS = "kc_construction";
export const MUNICIPALITY_ATTR_WATER = "water";
export const MUNICIPALITY_ATTR_SEWERAGE = "sewerage";
export const MUNICIPALITY_ATTR_WATER_GRADIENT = "water_cover";
export const MUNICIPALITY_ATTR_SEWERAGE_GRADIENT = "sewerage_cover";
export const MUNICIPALITY_ATTR_TITLE = "nm4";
export const MUNICIPALITY_ATTR_POPULATION = "population";
export const MUNICIPALITY_ATTR_ROMANI_POPULATION = "romani_population_rate";
export const MUNICIPALITY_ATTR_ROMANI_POPULATION_TOTAL = "romani_population";

export const GRADIENT_WATER_PERCENT = [{
    value: 10,
    color: "#e6bb81"
},{
    value: 25,
    color: "#e6a957"
},{
    value: 50,
    color: "#bd8233"
},{
    value: 75,
    color: "#a36818"
},{
    value: 100,
    color: "#6e4610"
}]

export const GRADIENT_WATER = [{
    value: 0.1,
    color: "#c9daff"
},{
    value: 0.25,
    color: "#99b9ff"
},{
    value: 0.50,
    color: "#6896fc"
},{
    value: 0.75,
    color: "#3c77fa"
},{
    value: 1,
    color: "#0a46c9"
}]

export const GRADIENT_SEWERAGE = [{
    value: 0.1,
    color: "#e6bb81"
},{
    value: 0.25,
    color: "#e6a957"
},{
    value: 0.50,
    color: "#bd8233"
},{
    value: 0.75,
    color: "#a36818"
},{
    value: 1,
    color: "#6e4610"
}]

export const GRADIENT_POPULATION = [{
    value: 0.1,
    color: "#e3ffe7"
},{
    value: 0.25,
    color: "#a6edb0"
},{
    value: 0.50,
    color: "#4ec25e"
},{
    value: 0.75,
    color: "#1d912d"
},{
    value: 1,
    color: "#055911"
}]

export const GRADIENT_POP2 = [{
    value: 5,
    color: "#e3ffe7"
},{
    value: 10,
    color: "#a6edb0"
},{
    value: 15,
    color: "#4ec25e"
},{
    value: 20,
    color: "#1d912d"
},{
    value: 100,
    color: "#055911"
}]


export const GRADIENT1 = [{
    value: 25,
    color: "#b0edff"
},{
    value: 50,
    color: "#71c9e3"
},{
    value: 75,
    color: "#37a0bf"
},{
    value: 90,
    color: "#147b99"
},{
    value: 100,
    color: "#045c75"
}]

export const GRADIENT2 = [{
    value: 10,
    color: "#ffcfcf"
},{
    value: 25,
    color: "#ff8585"
},{
    value: 50,
    color: "#ed4747"
},{
    value: 75,
    color: "#a81e1e"
},{
    value: 100,
    color: "#660303"
}]


export const GRADIENT3 = [{
    value: 5,
    color: "#ffbac3"
},{
    value: 10,
    color: "#ff8090"
},{
    value: 15,
    color: "#f24b60"
},{
    value: 20,
    color: "#c41027"
},{
    value: 36,
    color: "#940013"
}]

export const GRADIENT4 = [{
    value: 10,
    color: "#a9ffa6"
},{
    value: 25,
    color: "#69f564"
},{
    value: 50,
    color: "#21c21b"
},{
    value: 75,
    color: "#068a01"
},{
    value: 100,
    color: "#044f01"
}]

export const GRADIENT5 = [{
    value: 10,
    color: "#adf4ff"
},{
    value: 25,
    color: "#6bdced"
},{
    value: 50,
    color: "#30a6b8"
},{
    value: 75,
    color: "#177f8f"
},{
    value: 100,
    color: "#035d6b"
}]

export const GRADIENT6 = [{
    value: 25,
    color: "#c3c5fa"
},{
    value: 50,
    color: "#9397fa"
},{
    value: 75,
    color: "#565cf5"
},{
    value: 90,
    color: "#3036d9"
},{
    value: 100,
    color: "#0b1099"
}]

export const GRADIENT7 = [{
    value: 10,
    color: "#f9d6ff"
},{
    value: 25,
    color: "#f09cff"
},{
    value: 50,
    color: "#e65cff"
},{
    value: 75,
    color: "#d324f2"
},{
    value: 100,
    color: "#9209ab"
}]

export const GRADIENT8 = [{
    value: 10,
    color: "#ffbac3"
},{
    value: 25,
    color: "#ff8090"
},{
    value: 50,
    color: "#f24b60"
},{
    value: 75,
    color: "#c41027"
},{
    value: 100,
    color: "#940013"
}]
