import general from './general';
import map from './map';
import municipalityLayer from './municipalityLayer';
import identification from './identification';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
    general,
    municipalityLayer,
    identification,
    map
})

export default rootReducer