import React, { useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import BuildingIcon from '@material-ui/icons/HomeWorkOutlined';
import SearchIcon from '@material-ui/icons/Search';
import { useDispatch, useSelector } from 'react-redux';
import { List, ListItem } from '@material-ui/core';
import { MUNICIPALITY_ATTR_TITLE, MUNICIPALITY_ATTR_POPULATION, MUNICIPALITY_ATTR_ROMANI_POPULATION, MUNICIPALITY_ATTR_ROMANI_POPULATION_TOTAL } from '../constants';
import {Stroke, Style, Fill} from 'ol/style';
import {getProjects, getConstuctions} from './MunicipalityLayer'
import Source from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { useMap } from '../_core/hooks/useMap';

const useStyles = makeStyles(theme => ({
  root: {
    position: "absolute",
    top: 16,
    right: 16,
    width: "70%",
    maxWidth: 400
  },
  resultsPaper: {
    marginTop: 16
  },
  inputPaper: {
    alignItems: 'center',
    padding: '2px 4px',
    display: 'flex',
    width: "100%",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

const style = new Style({
  fill: new Fill({
    color: 'rgba(255,255,255,0)'
  }),
  stroke: new Stroke({
    color: "#00f",
    width: 2
  })
})



export default function CustomizedInputBase() {

  
  const classes = useStyles();
  const dispatch = useDispatch();
  const map = useMap()
  const {searchResults} = useSelector(state => state.municipalityLayer) 

  const search = (e) => {
    const text = e.target.value;

    if(text.length > 2)
      dispatch({type: "SEARCH", search: text})
    else dispatch({type: "SET_SEARCH_RESULTS", results: []})
  }

  const vector = new VectorLayer({
      source: new Source()
  })

  useEffect(() => {
    map.addLayer(vector)
    return () => {
      map.removeLayer(vector)
    }
  }, [map, vector])

  const highlight = useCallback((hover, feature) => {
    vector.getSource().clear()
    if(hover){
       feature.setStyle(style)
      vector.getSource().addFeature(feature)
    } 
  }, [vector])

  const zoomToFeature = useCallback((feature) => {
      const data = {
        title: feature.get(MUNICIPALITY_ATTR_TITLE),
        population: feature.get(MUNICIPALITY_ATTR_POPULATION),
        romaniPopulation: feature.get(MUNICIPALITY_ATTR_ROMANI_POPULATION),
        romaniPopulationTotal: feature.get(MUNICIPALITY_ATTR_ROMANI_POPULATION_TOTAL),
        projects: getProjects(feature),
        constructions: getConstuctions(feature)
    }
    dispatch({type: "SET_SEARCH", ...data, open: true})
    dispatch({type: "SET_SEARCH_RESULTS", results: []})
  }, [dispatch])

  return (
    <div className={classes.root}>
        <Paper className={classes.inputPaper} component="div" >
        <InputBase
            className={classes.input}
            placeholder="Napíšte názov obce"
            onChange={(e) => search(e)}
            inputProps={{ 'aria-label': 'Vyhľadať obec' }}
        />
        <IconButton type="submit" className={classes.iconButton} aria-label="search">
            <SearchIcon />
        </IconButton>
        </Paper>
        {(searchResults.length > 0) && 
            <Paper className={classes.resultsPaper}>
                <List>
                {
                    searchResults.map((feature, idx) => (
                        <ListItem 
                          button 
                          key={idx}
                          onMouseOver={() => highlight(true, feature)} 
                          onMouseOut={() => highlight(false, feature)} 
                          onClick={() => zoomToFeature(feature)}
                        >
                            <ListItemIcon>
                                <BuildingIcon />
                            </ListItemIcon>
                            <ListItemText                              
                                primary={feature.get(MUNICIPALITY_ATTR_TITLE)} 
                                style={{paddingRight: 24}}
                            />
                        </ListItem>
                    ))
                }
                </List>
            </Paper>
        }
    </div>
  );
}