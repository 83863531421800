import { withStyles } from "@material-ui/core";

const GlobalCss = withStyles(theme => ({
  "@global": {
    "input::-ms-clear, input::-ms-reveal": {
      display: "none"
    },
    "::-webkit-scrollbar": {
      width: "5px",
      height: "5px"
    },
    "::-webkit-scrollbar-track": {
      background: theme.palette.grey[300]
    },
    "::-webkit-scrollbar-thumb": {
      background: theme.palette.grey[500]
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: theme.palette.grey[600]
    }
  }
}))(() => null);

export default GlobalCss;