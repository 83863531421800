import { 
    CardHeader, 
    Card, 
    Dialog, 
    withStyles, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableRow,
    Typography
} from "@material-ui/core";
import React, { useCallback, useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useMap } from "../_core/hooks/useMap"
import {isEmpty} from "lodash"
import ol_vector_Source from "ol/source/Vector"
import ol_format_GeoJSON from "ol/format/GeoJSON"
import ol_layer_VectorImage from 'ol/layer/VectorImage';
import ol_Feature from 'ol/Feature';
import {Stroke, Style, Fill, Circle} from 'ol/style';

const redStyle = new Style({
    stroke: new Stroke({
        color: '#FF0000',
        width: 1
    }),
    fill: new Fill({
        color: 'rgba(255,255,255,0.1)'
    }),
    image: new Circle({
        fill: new Fill({
            color: 'rgba(255,255,255,0.1)'
        }),
        stroke: new Stroke({
            color: '#FF0000',
            width: 1
        }),
        radius: 9
    })
})   

const FeatureIdentification = ({
    classes
}) => {

    const map = useMap();
    const {open, results, attributes} = useSelector(state => state.identification)
    const dispatch = useDispatch()

    const addIdentificationResult = useCallback((result) => {
        dispatch({type: "ADD_IDENTIFICATION_RESULT", result});
    }, [dispatch])

    const clearIdentification = useCallback((payload) => {
        dispatch({type: "CLEAR_IDENTIFICATION", payload});
    }, [dispatch])

    const closeDialog = useCallback(() => {
        clearIdentification();
    }, [clearIdentification])

    const source = useMemo(() => new ol_vector_Source({
        format: new ol_format_GeoJSON()
    }), [])

    useEffect(() => {
        
        const layer = new ol_layer_VectorImage({
            imageRatio: 2,
            visible: true,
            title: "Identification",
            zIndex: 99,
            source,
            style: redStyle
        })

        map.addLayer(layer)
        return () => {
            map.removeLayer(layer)
        }
    }, [map, source])

    useEffect(() => {
        results.forEach(r => {
            const f = new ol_Feature({ geometry: r.geom })
            source.addFeature(f)
        })
        return () => {
            source.clear()
        }
    }, [source, results])

    useEffect(() => {

        const clickHandler = function (evt) {
            this.forEachFeatureAtPixel(evt.pixel, function (feature, layer) {
                const props = feature.getProperties()
                debugger
                const identificationProperties = attributes.reduce((prev, attrCfg) => {
                    if(props[attrCfg.attr] !== undefined){
                        const fValue = attrCfg.format ? attrCfg.format(props[attrCfg.attr]) : props[attrCfg.attr];
                        prev[attrCfg.alias || attrCfg.attr] = fValue;
                    }
                    return prev;
                }, {})
                
                if(Object.keys(identificationProperties).length === 0){
                    return null;
                }

                addIdentificationResult({
                    layer: layer.get("identificationTitle"), 
                    properties: identificationProperties,
                    geom: feature.getGeometry()
                })
            });
        };

        map.on("singleclick", clickHandler);

        return () => {
            map.un("singleClick", clickHandler)
        }

    }, [map, addIdentificationResult, attributes])
    

    return (
        <Dialog onClose={closeDialog} aria-labelledby="simple-dialog-title" classes={{paper: classes.root}} open={!isEmpty(results) && open} >
                <TableContainer classes={{root: classes.content}} component={Card}>
                        <CardHeader title="Identifikácia" />
                        {
                            results.map((result, idx) => {
                                return (
                                    <Card style={{marginBottom: 8, paddingTop: 8}} elevation={1}>
                                        <Typography className={classes.title} gutterButton>{result.layer}</Typography>
                                        <Table key={idx} className={classes.table} aria-label="simple table">
                                            <TableBody>
                                            {
                                                Object.entries(result.properties).map(([key, value]) => (
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                        {key}
                                                        </TableCell>
                                                        <TableCell align="right">{value && value.toString()}</TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                            </TableBody>
                                        </Table>
                                    </Card>
                                )
                            }
                        )}
            </TableContainer>
        </Dialog>
    )
}

const styles = (theme) => ({
    root: {
        maxWidth: 600,
        width: "100%"
    },
    content: {
        width: "100%",
        maxHeight: 450,
        overflowY: "auto",
        padding: 8
    },
    title: {
        paddingLeft: theme.spacing(2),
        fontWeight: 700
    }
})

export default withStyles(styles)(FeatureIdentification)