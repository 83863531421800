import React from "react"
import { useSelector } from "react-redux";
import { useMap } from "../_core/hooks/useMap";

const Plugin = ({
    children
}) => {

    const map = useMap();
    const state = useSelector(state => state);

    const reducers = {

    }

    return React.Children.map(children, child => { 
        return React.cloneElement(child, { 
            ...child.props, 
            ...state, 
            ...reducers, 
            map 
        })
    })
}

Plugin.defaultProps = {
    state: {},
    reducers: {}
}

export default Plugin