import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Map from './_core/Map';
import Layout from './components/Layout'
import SearchInput from './components/SearchInput'
import Providers from './_core/Providers';
import theme from './theme';
import LayersLoader from './_core/layers';
import Plugins from "./_plugins";
import FeatureIdentification from './_plugins/FeaturesIdentification';

const App = ({
  theme,
  beforeLayers,
  afterLayers,
  plugins
}) => (
  <Providers theme={theme}>
    <Router>
      <Layout>         
        <Map>
          { beforeLayers }
          <LayersLoader/>
          { afterLayers }
          <SearchInput/>
          <FeatureIdentification />
          <Plugins>
              {plugins}
          </Plugins>
        </Map>
      </Layout>
    </Router>
  </Providers>
)

App.defaultProps = {
  theme: theme,
  plugins: [],
  beforeLayers: [],
  afterLayers: []
}

export default App;
