import ol_layer_Tile from 'ol/layer/Tile';
import ol_source_OSM from 'ol/source/OSM';
import { useEffect, useState, useContext } from 'react';
import { MapContext } from '../MapProvider';

const OsmLayer = (props) => {

    const map = useContext(MapContext)
    const { visible, zindex } = props;

    const l = new ol_layer_Tile({
        visible,
        zIndex: zindex,
        source: new ol_source_OSM({
            crossOrigin: 'anonymous',
            "url" : "http://a.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
        })
    })

    const [olLayer] = useState(l)

    useEffect(() => {
        olLayer.setProperties({ ...olLayer.getProperties(), ...props })
    }, [olLayer, props])

    useEffect(() => {
        olLayer && olLayer.setVisible(visible)
    }, [olLayer, visible])
    
    // useEffect(() => {
    //     olLayer && olLayer.setZIndex(0.0001 * (zindex - 10000))
    // }, [olLayer, zindex])

    useEffect(() => {
        olLayer.id = props.id;
        map.addLayer(olLayer)
    }, [map, olLayer, props.id])

    return null

};

export default OsmLayer;
