import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MapContext } from './MapProvider';

const styles = {
    // width: "100%",
    // height: "100%",
    width: "100%",
    height: "100%",
    position: "absolute",
}

const MapPanel = ({
    children
}) => {
    
    const map = useContext(MapContext)

    useEffect(() => { 
        map.setTarget('map')
        return () => {
            map.setTarget(null)
        }
    },[map])

    return (
        <>
            <div 
                style={styles}
                id="map"
            />
            {children}
        </>
    )
    
}

MapPanel.propTypes = {
   classes: PropTypes.object,
   children: PropTypes.any
}

MapPanel.defaultProps = {
   
}

export default MapPanel