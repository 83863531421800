const initState = {
    baseLayers: [
        {
            id: 1,
            title: "OpenStreetMap",
            type: "osm",
            baselayer: true,
            visible: true,
            image: "icon_1.png",
            zIndex: -1
        },
        // {
        //     id: 2,
        //     title: "Ortofotomapa SR",
        //     type: "tilewms",
        //     baselayer: true,
        //     url: "https://zbgisws.skgeodesy.sk/zbgis_ortofoto_wms/service.svc/get?",
        //     visible: true,
        //     image: "icon_2.png",
        //     params: {
        //         LAYERS: "0,1",
        //         FORMAT: "image/jpeg"
        //     }
        // },
        // {
        //     id: 3,
        //     title: "ZBGIS",
        //     type: "tilewms",
        //     baselayer: true,
        //     image: "icon_3.png",
        //     url: "https://zbgisws.skgeodesy.sk/zbgis_wms_featureinfo/service.svc/get",
        //     visible: true,
        //     params: {
        //         LAYERS: "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,95,97,98,99,100,102,103,104,105,107,109,110,111,112,114,115,116,117,118,119,121,122,123,124,125,126,128,130,131,132,133,134,135,136,137,138,139,140,141,142,143,144,145,148,150,152,153,154,155,156,157,159,160,161,162,163,164,166",
        //         FORMAT: "image/png"
        //     }
        // },
    ],
    layers: [
        // {
        //     id: 1,
        //     title: "KN - Určený operát",
        //     type: "tilewms",
        //     url: "https://kataster.skgeodesy.sk/eskn/services/NR/uo_wms_norm/MapServer/WmsServer?",
        //     visible: false,
        //     params: {
        //         LAYERS: "0,1,2,3,4,5",
        //         FORMAT: "image/png"
        //     }
        // },
        // {
        //     id: 2,
        //     title: "KN - Katastrálna mapa",
        //     type: "tilewms",
        //     url: "https://kataster.skgeodesy.sk/eskn/services/NR/kn_wms_norm/MapServer/WmsServer",
        //     visible: false,
        //     params: {
        //         LAYERS: "0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15",
        //         FORMAT: "image/png"
        //     }
        // }
    ],
    interactiveLayers: [
        {
            id: 1,
            title: "Hranica SR",
            displayInLayerTree: false,
            visible: true,
            style: { stroke: { width: 4, color: "#333" } },
            url: "/data/sr.geojson"
        }, {
            id: 2,
            title: "Hranica kraja",
            displayInLayerTree: false,
            visible: true,
            style: { stroke: { width: 2, color: "#333" } },
            url: "/data/kraj.geojson"
        }, {
            id: 3,
            title: "Hranica okres",
            displayInLayerTree: false,
            visible: true,
            style: { stroke: { width: 1, color: "#333" } },
            url: "/data/okres.geojson"
        },       
        {
            id: 4,
            title: "Podiel rómskych komunít na celkovom obyvateľstve okresov Slovenskej republiky",
            displayInLayerTree: true,
            zIndex: -0.1,
            group: "Okresy",
            legend: {
                values: [{
                    value: 1,
                    color: "#FFF6B1"
                },{
                    value: 5,
                    color: "#AAD7D6"
                },{
                    value: 10,
                    color: "#58C0E1"
                },{
                    value: 20,
                    color: "#2A95C0"
                },{
                    value: 100,
                    color: "#376A9D"
                }],
                format: (value) => `${value}%`,
            },
            visible: false,
            style: {
                fill: { color: "rgba(255,255,255,0.4)", gradient: { colors: [{
                    value: 1,
                    color: "#FFF6B1"
                },{
                    value: 5,
                    color: "#AAD7D6"
                },{
                    value: 10,
                    color: "#58C0E1"
                },{
                    value: 20,
                    color: "#2A95C0"
                },{
                    value: 100,
                    color: "#376A9D"
                }], attribute: "podiel_okr"  } },
                point: { radius: 4  }
            },
            url: "/data/okres_2021.geojson",
            filter: {
                type: "<>",
                attribute: "podiel_okr",
                from: 0,
                to: 100,
                min: 0,
                max: 100
            }
        },        
        /*******
         * 
         * OBCE
         * 
         */

         {
            id: 19,
            title: "Podiel rómskych komunít na celkovom obyvateľstve obcí Atlasu rómskych komunít",
            identificationTitle: "Kategória - Obce",
            displayInLayerTree: true,
            group: "Obce",
            legend: {
                values: [{
                    value: 20,
                    color: "#FFF6B1"
                },{
                    value: 40,
                    color: "#AAD7D6"
                },{
                    value: 60,
                    color: "#58C0E1"
                },{
                    value: 80,
                    color: "#2A95C0"
                },{
                    value: 100,
                    color: "#376A9D"
                }],
                format: (value) => `${value}%`,
            },
            visible: false,
            style: {
                stroke: { width: 1, color: "#333" },
                fill: { color: "rgba(255,255,255,0.4)", gradient: { colors: [{
                    value: 20,
                    color: "#FFF6B1"
                },{
                    value: 40,
                    color: "#AAD7D6"
                },{
                    value: 60,
                    color: "#58C0E1"
                },{
                    value: 80,
                    color: "#2A95C0"
                },{
                    value: 100,
                    color: "#376A9D"
                }], attribute: "podiel_r_3"  } },
                point: { radius: 4  }
            },
            url: "/data/obce_2021.geojson",
            filter: {
                type: "<>",
                attribute: "podiel_r_3",
                from: 0,
                to: 100,
                min: 0,
                max: 100
            }
        },
        {
            id: 20,
            title: "Obce podľa prevažne používaného jazyka rómskej komunity",
            identificationTitle: "Kategória - Obce",
            displayInLayerTree: true,
            group: "Obce",
            legend: {
                values: [{
                    value: "Rómsky",
                    color: "#AAD7D6"
                },{
                    value: "Slovenský",
                    color: "#2A95C0"
                },{
                    value: "Maďarský",
                    color: "#EC9494"
                },{
                    value: "Rusínsky",
                    color: "#FFF6B1"
                }],
                format: (value) => `${value}`,
            },
            visible: false,
            style: {
                stroke: { width: 1, color: "#333" },
                fill: { color: "rgba(255,255,255,0.4)", categories: { colors: [{
                    value: "Rómsky",
                    color: "#AAD7D6"
                },{
                    value: "Slovenský",
                    color: "#2A95C0"
                },{
                    value: "Maďarský",
                    color: "#EC9494"
                },{
                    value: "Rusínsky",
                    color: "#FFF6B1"
                }], attribute: "jazykova_3"  } },
                point: { radius: 4  }
            },
            url: "/data/obce_2021.geojson"
        },
        {
            id: 31,
            title: "Obce s výraznými rozdielmi v dostupnosti vodovodu medzi RK a ostatnými obyvateľmi",
            identificationTitle: "Kategória - Obce",
            displayInLayerTree: true,
            group: "Obce",
            visible: false,
            legend: {
                values: [{
                    value: "Nie",
                    color: "#58C0E1"
                },{
                    value: "Áno",
                    color: "#CF6A8F"
                }]
            },
            // style: {
            //     stroke: { width: 1, color: "#333" },
            //     fill: { color: "rgba(255,0,0,1)" },
            //     point: { radius: 4  }
            // },
            url: "/data/obce_2021.geojson",
            style: {
                stroke: { width: 1, color: "#333" },
                fill: { color: "rgba(255,255,255,0.4)", categories: { colors: [{
                    value: "Nie",
                    color: "#58C0E1"
                },{
                    value: "Áno",
                    color: "#CF6A8F"
                }], attribute: "vyrazne_11"  } },
                point: { radius: 4  }
            },
            // filter: {
            //     attributes: ['Áno', 'Nie'],
            //     type: "=",
            //     attribute: "vyrazne_11",
            //     value: "Áno"
            // }
        },
        {
            id: 29,
            title: "Obce s výraznými rozdielmi v dostupnosti kanalizácie medzi RK a ostatnými obyvateľmi",
            identificationTitle: "Kategória - Obce",
            displayInLayerTree: true,
            group: "Obce",
            visible: false,
            legend: {
                values: [{
                    value: "Nie",
                    color: "#58C0E1"
                },{
                    value: "Áno",
                    color: "#CF6A8F"
                }]
            },
            // style: {
            //     stroke: { width: 1, color: "#333" },
            //     fill: { color: "rgba(255,0,0,1)" },
            //     point: { radius: 4  }
            // },
            url: "/data/obce_2021.geojson",
            style: {
                stroke: { width: 1, color: "#333" },
                fill: { color: "rgba(255,255,255,0.4)", categories: { colors: [{
                    value: "Nie",
                    color: "#58C0E1"
                },{
                    value: "Áno",
                    color: "#CF6A8F"
                }], attribute: "vyrazne_5"  } },
                point: { radius: 4  }
            },
            // filter: {
            //     attributes: ['Áno', 'Nie'],
            //     type: "=",
            //     attribute: "vyrazne_5",
            //     value: "Áno"
            // }
        },
        {
            id: 28,
            title: "Obce s výraznými rozdielmi v miere pripojenia na elektrickú sieť medzi RK a ostatnými obyvateľmi",
            identificationTitle: "Kategória - Obce",
            displayInLayerTree: true,
            group: "Obce",
            visible: false,
            legend: {
                values: [{
                    value: "Nie",
                    color: "#58C0E1"
                },{
                    value: "Áno",
                    color: "#CF6A8F"
                }]
            },
            // style: {
            //     stroke: { width: 1, color: "#333" },
            //     fill: { color: "rgba(255,0,0,1)" },
            //     point: { radius: 4  }
            // },
            style: {
                stroke: { width: 1, color: "#333" },
                fill: { color: "rgba(255,255,255,0.4)", categories: { colors: [{
                    value: "Nie",
                    color: "#58C0E1"
                },{
                    value: "Áno",
                    color: "#CF6A8F"
                }], attribute: "vyrazne_2"  } },
                point: { radius: 4  }
            },
            url: "/data/obce_2021.geojson",
            // filter: {
            //     attributes: ['Áno', 'Nie'],
            //     type: "=",
            //     attribute: "vyrazne_2",
            //     value: "Áno"
            // }
        },
        {
            id: 30,
            title: "Obce s výraznými rozdielmi v dostupnosti plynu medzi RK a ostatnými obyvateľmi",
            identificationTitle: "Kategória - Obce",
            displayInLayerTree: true,
            group: "Obce",
            visible: false,
            legend: {
                values: [{
                    value: "Nie",
                    color: "#58C0E1"
                },{
                    value: "Áno",
                    color: "#CF6A8F"
                }]
            },
            style: {
                stroke: { width: 1, color: "#333" },
                fill: { color: "rgba(255,255,255,0.4)", categories: { colors: [{
                    value: "Nie",
                    color: "#58C0E1"
                },{
                    value: "Áno",
                    color: "#CF6A8F"
                }], attribute: "vyrazne_8"  } },
                point: { radius: 4  }
            },
            // style: {
            //     stroke: { width: 1, color: "#333" },
            //     fill: { color: "rgba(255,0,0,1)" },
            //     point: { radius: 4  }
            // },
            url: "/data/obce_2021.geojson",
            // filter: {
            //     attributes: ['Áno', 'Nie'],
            //     type: "=",
            //     attribute: "vyrazne_8",
            //     value: "Áno"
            // }
        },
        {
            id: 26,
            title: "Obce s prítomnosťou terénnej sociálnej práce",
            identificationTitle: "Kategória - Obce",
            displayInLayerTree: true,
            group: "Obce",
            visible: false,
            // style: {
            //     stroke: { width: 1, color: "#333" },
            //     fill: { color: "rgba(255,0,0,1)" },
            //     point: { radius: 4  }
            // },
            legend: {
                values: [{
                    value: "Nie",
                    color: "#58C0E1"
                },{
                    value: "Áno",
                    color: "#CF6A8F"
                }]
            },
            style: {
                stroke: { width: 1, color: "#333" },
                fill: { color: "rgba(255,255,255,0.4)", categories: { colors: [{
                    value: "Nie",
                    color: "#58C0E1"
                },{
                    value: "Áno",
                    color: "#CF6A8F"
                }], attribute: "pokrytie14"  } },
                point: { radius: 4  }
            },
            url: "/data/obce_2021.geojson",
            // filter: {
            //     attributes: ["Áno","Nie"],
            //     type: "=",
            //     attribute: "pokrytie14",
            //     value: "Áno"
            // }
        },
        {
            id: 24,
            title: "Obce s prítomnosťou komunitného centra",
            identificationTitle: "Kategória - Obce",
            displayInLayerTree: true,
            group: "Obce",
            visible: false,
            // style: {
            //     stroke: { width: 1, color: "#333" },
            //     fill: { color: "rgba(255,0,0,1)" },
            //     point: { radius: 4  }
            // },
            url: "/data/obce_2021.geojson",
            // filter: {
            //     attributes: ["Áno","Nie"],
            //     type: "=",
            //     attribute: "pokrytie_6",
            //     value: "Áno"
            // }
            legend: {
                values: [{
                    value: "Nie",
                    color: "#58C0E1"
                },{
                    value: "Áno",
                    color: "#CF6A8F"
                }]
            },
            style: {
                stroke: { width: 1, color: "#333" },
                fill: { color: "rgba(255,255,255,0.4)", categories: { colors: [{
                    value: "Nie",
                    color: "#58C0E1"
                },{
                    value: "Áno",
                    color: "#CF6A8F"
                }], attribute: "pokrytie_6"  } },
                point: { radius: 4  }
            },
        },
        {
            id: 27,
            title: "Obce s prítomnosťou asistentov osvety zdravia",
            identificationTitle: "Kategória - Obce",
            displayInLayerTree: true,
            group: "Obce",
            visible: false,
            // style: {
            //     stroke: { width: 1, color: "#333" },
            //     fill: { color: "#ff0000" },
            //     point: { radius: 4  }
            // },
            url: "/data/obce_2021.geojson",
            // filter: {
            //     attributes: ["Áno","Nie"],
            //     type: "=",
            //     attribute: "pokrytie18",
            //     value: "Áno"
            // }
            legend: {
                values: [{
                    value: "Nie",
                    color: "#58C0E1"
                },{
                    value: "Áno",
                    color: "#CF6A8F"
                }]
            },
            style: {
                stroke: { width: 1, color: "#333" },
                fill: { color: "rgba(255,255,255,0.4)", categories: { colors: [{
                    value: "Nie",
                    color: "#58C0E1"
                },{
                    value: "Áno",
                    color: "#CF6A8F"
                }], attribute: "pokrytie18"  } },
                point: { radius: 4  }
            },
        },
        {
            id: 25,
            title: "Obce s prítomnosťou miestnej občianskej poriadkovej služby",
            identificationTitle: "Kategória - Obce",
            displayInLayerTree: true,
            group: "Obce",
            visible: false,
            // style: {
            //     stroke: { width: 1, color: "#333" },
            //     fill: { color: "rgba(255,0,0,1)" },
            //     point: { radius: 4  }
            // },
            url: "/data/obce_2021.geojson",
            // filter: {
            //     attributes: ["Áno","Nie"],
            //     type: "=",
            //     attribute: "pokrytie10",
            //     value: "Áno"
            // }
            legend: {
                values: [{
                    value: "Nie",
                    color: "#58C0E1"
                },{
                    value: "Áno",
                    color: "#CF6A8F"
                }]
            },
            style: {
                stroke: { width: 1, color: "#333" },
                fill: { color: "rgba(255,255,255,0.4)", categories: { colors: [{
                    value: "Nie",
                    color: "#58C0E1"
                },{
                    value: "Áno",
                    color: "#CF6A8F"
                }], attribute: "pokrytie10"  } },
                point: { radius: 4  }
            }
        },
        {
            id: 22,
            title: "Obce podľa miery prítomnosti pomocných profesií",
            identificationTitle: "Kategória - Obce",
            displayInLayerTree: true,
            group: "Obce",
            legend: {
                values: [{
                    value: "4",
                    color: "#2A95C0"
                },{
                    value: "3",
                    color: "#AAD7D6"
                },{
                    value: "2",
                    color: "#FFF6B1"
                },{
                    value: "1",
                    color: "#EC9494"
                },{
                    value: "0",
                    color: "#CF6A8F"
                }],
                format: (value) => `${value}`,
            },
            visible: false,
            style: {
                stroke: { width: 1, color: "#333" },
                fill: { color: "rgba(255,0,0,1)", categories: { colors: [{
                    value: "4",
                    color: "#2A95C0"
                },{
                    value: "3",
                    color: "#AAD7D6"
                },{
                    value: "2",
                    color: "#FFF6B1"
                },{
                    value: "1",
                    color: "#EC9494"
                },{
                    value: "0",
                    color: "#CF6A8F"
                }], attribute: "pokrytie_3"  } },
                point: { radius: 4  }
            },
            url: "/data/obce_2021.geojson"
        },
        {
            id: 21,
            title: "Obce s rómskymi poslancami miestneho zastupiteľstva",
            identificationTitle: "Kategória - Obce",
            displayInLayerTree: true,
            group: "Obce",
            visible: false,
            // style: {
            //     stroke: { width: 1, color: "#333" },
            //     fill: { color: "rgba(255,0,0,1)" },
            //     point: { radius: 4  }
            // },
            url: "/data/obce_2021.geojson",
            legend: {
                values: [{
                    value: "Nie",
                    color: "#58C0E1"
                },{
                    value: "Áno",
                    color: "#CF6A8F"
                }]
            },
            style: {
                stroke: { width: 1, color: "#333" },
                fill: { color: "rgba(255,255,255,0.4)", categories: { colors: [{
                    value: "Nie",
                    color: "#58C0E1"
                },{
                    value: "Áno",
                    color: "#CF6A8F"
                }], attribute: "romovia_3"  } },
                point: { radius: 4  }
            },
            // filter: {
            //     attributes: ["Áno", "Nie"],
            //     type: "=",
            //     attribute: "romovia_3",
            //     value: "Áno"
            // }
        },
        {
            id: 23,
            title: "Obce s pastoračnou činnosťou v RK podľa cirkví",
            identificationTitle: "Kategória - Obce",
            displayInLayerTree: true,
            group: "Obce",
            visible: false,
            style: {
                stroke: { width: 1, color: "#333" },
                fill: { color: "rgba(255,0,0,1)" },
                point: { radius: 4  }
            },
            url: "/data/obce_2021.geojson",
            filter: {
                attributes: ["viaceré", "Nie", "ECAV", "RefKC", "BJB", "RKC", "KM Maranata", "GKC", "BJB", "ACS", "Armáda spásy", "JS", "KC Milosť"],
                type: "=",
                attribute: "pastorac_3",
                value: "viaceré"
            }
        },
        /**
         * Koncentracie
         */
        {
            id: 41,
            title: "Koncentrácie podľa ich typu",
            identificationTitle: "Kategória - Koncentrácie",
            displayInLayerTree: true,
            group: "Koncentrácie",
            visible: false,
            legend: {
                values: [{
                    value: "Vo vnútri",
                    color: "#EC9494"
                },{
                    value: "Na okraji",
                    color: "#AAD7D6"
                },{
                    value: "Mimo obce",
                    color: "#2A95C0"
                }],
                format: (value) => `${value}`,
            },
            style: {
                stroke: { width: 1, color: "#333" },
                fill: { color: "rgba(255,255,255,0.4)", categories: { colors: [{
                    value: "Vo vnútri",
                    color: "#EC9494"
                },{
                    value: "Na okraji",
                    color: "#AAD7D6"
                },{
                    value: "Mimo obce",
                    color: "#2A95C0"
                }], attribute: "typ"  } },
                point: { radius: 4  }
            },
            url: "/data/koncentracie_2021.geojson"
        },
        
        {
            id: 6,
            title: "Koncentrácie podľa podielu subštandardných obydlí",
            identificationTitle: "Kategória - Koncentrácie",
            displayInLayerTree: true,
            visible: false,
            group: "Koncentrácie",
            legend: {
                values: [{
                    value: 20,
                    color: "#FFF6B1"
                },{
                    value: 40,
                    color: "#AAD7D6"
                },{
                    value: 60,
                    color: "#58C0E1"
                },{
                    value: 80,
                    color: "#2A95C0"
                },{
                    value: 100,
                    color: "#376A9D"
                }],
                format: (value) => `${value}%`,
            },
            style: {
                stroke: { width: 1, color: "#333" },
                fill: { color: "rgba(255,255,255,0.4)", gradient: { colors: [{
                    value: 20,
                    color: "#FFF6B1"
                },{
                    value: 40,
                    color: "#AAD7D6"
                },{
                    value: 60,
                    color: "#58C0E1"
                },{
                    value: 80,
                    color: "#2A95C0"
                },{
                    value: 100,
                    color: "#376A9D"
                }], attribute: "podiel"  } },
                point: { radius: 4  }
            },
            url: "/data/koncentracie_2021.geojson",
            filter: {
                type: "<>",
                attribute: "podiel",
                from: 0,
                to: 100,
                min: 0,
                max: 100
            }
        },
        {
            id: 7,
            title: "Koncentrácie podľa priemerného počtu ľudí na jedno obydlie",
            identificationTitle: "Kategória - Koncentrácie",
            displayInLayerTree: true,
            group: "Koncentrácie",
            visible: false,
            legend: {
                values: [{
                    value: 3,
                    color: "#2A95C0"
                },{
                    value: 6,
                    color: "#AAD7D6"
                },{
                    value: 10,
                    color: "#FFF6B1"
                },{
                    value: 20,
                    color: "#EC9494"
                },{
                    value: 100,
                    color: "#CF6A8F"
                }],
                format: (value) => `${value}`,
            },
            style: {
                stroke: { width: 1, color: "#333" },
                fill: { color: "rgba(255,255,255,0.4)", gradient: { colors: [{
                    value: 3,
                    color: "#2A95C0"
                },{
                    value: 6,
                    color: "#AAD7D6"
                },{
                    value: 10,
                    color: "#FFF6B1"
                },{
                    value: 20,
                    color: "#EC9494"
                },{
                    value: 100,
                    color: "#CF6A8F"
                }], attribute: "pod cislo"  } },
                point: { radius: 4  }
            },
            url: "/data/koncentracie_2021.geojson",
            filter: {
                type: "<>",
                attribute: "pod cislo",
                from: 0,
                to: 36,
                min: 0,
                max: 36
            }
        },
        {
            id: 8,
            title: "Koncentrácie podľa podielu vysporiadaných pozemkov",
            identificationTitle: "Kategória - Koncentrácie",
            displayInLayerTree: true,
            group: "Koncentrácie",
            visible: false,
            legend: {
                values: [{
                    value: 20,
                    color: "#376A9D"
                },{
                    value: 40,
                    color: "#2A95C0"
                },{
                    value: 60,
                    color: "#58C0E1"
                },{
                    value: 80,
                    color: "#AAD7D6"
                },{
                    value: 100,
                    color: "#FFF6B1"
                }],
                format: (value) => `${value}%`,
            },
            style: {
                stroke: { width: 1, color: "#333" },
                fill: { color: "rgba(255,255,255,0.4)", gradient: { colors: [{
                    value: 20,
                    color: "#376A9D"
                },{
                    value: 40,
                    color: "#2A95C0"
                },{
                    value: 60,
                    color: "#58C0E1"
                },{
                    value: 80,
                    color: "#AAD7D6"
                },{
                    value: 100,
                    color: "#FFF6B1"
                }], attribute: "vysporiad"  } },
                point: { radius: 4  }
            },
            url: "/data/koncentracie_2021.geojson",
            filter: {
                type: "<>",
                attribute: "vysporiad",
                from: 0,
                to: 100,
                min: 0,
                max: 100
            }
        },
        {
            id: 5,
            title: "Koncentrácie podľa miery využívania vodovodu",
            identificationTitle: "Kategória - Koncentrácie",
            displayInLayerTree: true,
            group: "Koncentrácie",
            legend: {
                values: [{
                    value: 20,
                    color: "#376A9D"
                },{
                    value: 40,
                    color: "#2A95C0"
                },{
                    value: 60,
                    color: "#58C0E1"
                },{
                    value: 80,
                    color: "#AAD7D6"
                },{
                    value: 100,
                    color: "#FFF6B1"
                }],
                format: (value) => `${value}%`,
            },
            visible: false,
            style: {
                stroke: { width: 1, color: "#333" },
                fill: { color: "rgba(255,255,255,0.4)", gradient: { colors: [{
                    value: 20,
                    color: "#376A9D"
                },{
                    value: 40,
                    color: "#2A95C0"
                },{
                    value: 60,
                    color: "#58C0E1"
                },{
                    value: 80,
                    color: "#AAD7D6"
                },{
                    value: 100,
                    color: "#FFF6B1"
                }], attribute: "vodovod"  } },
                point: { radius: 4  }
            },
            url: "/data/koncentracie_2021.geojson",
            filter: {
                type: "<>",
                attribute: "vodovod",
                from: 0,
                to: 100,
                min: 0,
                max: 100
            }
        }, 
        {
            id: 9,
            title: "Koncentrácie podľa miery využívania kanalizácie",
            identificationTitle: "Kategória - Koncentrácie",
            displayInLayerTree: true,
            visible: false,
            group: "Koncentrácie",
            legend: {
                values: [{
                    value: 20,
                    color: "#376A9D"
                },{
                    value: 40,
                    color: "#2A95C0"
                },{
                    value: 60,
                    color: "#58C0E1"
                },{
                    value: 80,
                    color: "#AAD7D6"
                },{
                    value: 100,
                    color: "#FFF6B1"
                }],
                format: (value) => `${value}%`,
            },
            style: {
                stroke: { width: 1, color: "#333" },
                fill: { color: "rgba(255,255,255,0.4)", gradient: { colors: [{
                    value: 20,
                    color: "#376A9D"
                },{
                    value: 40,
                    color: "#2A95C0"
                },{
                    value: 60,
                    color: "#58C0E1"
                },{
                    value: 80,
                    color: "#AAD7D6"
                },{
                    value: 100,
                    color: "#FFF6B1"
                }], attribute: "kanal"  } },
                point: { radius: 4  }
            },
            url: "/data/koncentracie_2021.geojson",
            filter: {
                type: "<>",
                attribute: "kanal",
                from: 0,
                to: 100,
                min: 0,
                max: 100
            }
        },
        {
            id: 10,
            title: "Koncentrácie podľa miery pripojenia na elektrickú sieť",
            identificationTitle: "Kategória - Koncentrácie",
            displayInLayerTree: true,
            group: "Koncentrácie",
            visible: false,
            legend: {
                values: [{
                    value: 20,
                    color: "#376A9D"
                },{
                    value: 40,
                    color: "#2A95C0"
                },{
                    value: 60,
                    color: "#58C0E1"
                },{
                    value: 80,
                    color: "#AAD7D6"
                },{
                    value: 100,
                    color: "#FFF6B1"
                }],
                format: (value) => `${value}%`,
            },
            style: {
                stroke: { width: 1, color: "#333" },
                fill: { color: "rgba(255,255,255,0.4)", gradient: { colors: [{
                    value: 20,
                    color: "#376A9D"
                },{
                    value: 40,
                    color: "#2A95C0"
                },{
                    value: 60,
                    color: "#58C0E1"
                },{
                    value: 80,
                    color: "#AAD7D6"
                },{
                    value: 100,
                    color: "#FFF6B1"
                }], attribute: "elektrik"  } },
                point: { radius: 4  }
            },
            url: "/data/koncentracie_2021.geojson",
            filter: {
                type: "<>",
                attribute: "elektrik",
                from: 0,
                to: 100,
                min: 0,
                max: 100
            }
        },
        {
            id: 11,
            title: "Koncentrácie podľa miery pokrytia verejným osvetlením",
            identificationTitle: "Kategória - Koncentrácie",
            displayInLayerTree: true,
            visible: false,
            group: "Koncentrácie",
            legend: {
                values: [{
                    value: 20,
                    color: "#376A9D"
                },{
                    value: 40,
                    color: "#2A95C0"
                },{
                    value: 60,
                    color: "#58C0E1"
                },{
                    value: 80,
                    color: "#AAD7D6"
                },{
                    value: 100,
                    color: "#FFF6B1"
                }],
                format: (value) => `${value}%`,
            },
            style: {
                stroke: { width: 1, color: "#333" },
                fill: { color: "rgba(255,255,255,0.4)", gradient: { colors: [{
                    value: 20,
                    color: "#376A9D"
                },{
                    value: 40,
                    color: "#2A95C0"
                },{
                    value: 60,
                    color: "#58C0E1"
                },{
                    value: 80,
                    color: "#AAD7D6"
                },{
                    value: 100,
                    color: "#FFF6B1"
                }], attribute: "VO"  } },
                point: { radius: 4  }
            },
            url: "/data/koncentracie_2021.geojson",
            filter: {
                type: "<>",
                attribute: "VO",
                from: 0,
                to: 100,
                min: 0,
                max: 100
            }
        },
        {
            id: 35,
            title: "Koncentrácie podľa miery využívania plynu",
            identificationTitle: "Kategória - Koncentrácie",
            displayInLayerTree: true,
            visible: false,
            group: "Koncentrácie",
            legend: {
                values: [{
                    value: 20,
                    color: "#376A9D"
                },{
                    value: 40,
                    color: "#2A95C0"
                },{
                    value: 60,
                    color: "#58C0E1"
                },{
                    value: 80,
                    color: "#AAD7D6"
                },{
                    value: 100,
                    color: "#FFF6B1"
                }],
                format: (value) => `${value}%`,
            },
            style: {
                stroke: { width: 1, color: "#333" },
                fill: { color: "rgba(255,255,255,0.4)", gradient: { colors: [{
                    value: 20,
                    color: "#376A9D"
                },{
                    value: 40,
                    color: "#2A95C0"
                },{
                    value: 60,
                    color: "#58C0E1"
                },{
                    value: 80,
                    color: "#AAD7D6"
                },{
                    value: 100,
                    color: "#FFF6B1"
                }], attribute: "plyn"  } },
                point: { radius: 4  }
            },
            url: "/data/koncentracie_2021.geojson",
            filter: {
                type: "<>",
                attribute: "plyn",
                from: 0,
                to: 100,
                min: 0,
                max: 100
            }
        },
        {
            id: 12,
            title: "Koncentrácie podľa miery vykurovania centrálnym zásobovaním tepla, plynom alebo elektrinou",
            identificationTitle: "Kategória - Koncentrácie",
            displayInLayerTree: true,
            group: "Koncentrácie",
            visible: false,
            legend: {
                values: [{
                    value: 20,
                    color: "#376A9D"
                },{
                    value: 40,
                    color: "#2A95C0"
                },{
                    value: 60,
                    color: "#58C0E1"
                },{
                    value: 80,
                    color: "#AAD7D6"
                },{
                    value: 100,
                    color: "#FFF6B1"
                }],
                format: (value) => `${value}%`,
            },
            style: {
                stroke: { width: 1, color: "#333" },
                fill: { color: "rgba(255,255,255,0.4)", gradient: { colors: [{
                    value: 20,
                    color: "#376A9D"
                },{
                    value: 40,
                    color: "#2A95C0"
                },{
                    value: 60,
                    color: "#58C0E1"
                },{
                    value: 80,
                    color: "#AAD7D6"
                },{
                    value: 100,
                    color: "#FFF6B1"
                }], attribute: "kurenie"  } },
                point: { radius: 4  }
            },
            url: "/data/koncentracie_2021.geojson",
            filter: {
                type: "<>",
                attribute: "kurenie",
                from: 0,
                to: 100,
                min: 0,
                max: 100
            }
        },
        {
            id: 13,
            title: "Koncentrácie podľa miery pokrytia kuka alebo bobor nádobami",
            identificationTitle: "Kategória - Koncentrácie",
            displayInLayerTree: true,
            group: "Koncentrácie",
            visible: false,
            legend: {
                values: [{
                    value: 20,
                    color: "#376A9D"
                },{
                    value: 40,
                    color: "#2A95C0"
                },{
                    value: 60,
                    color: "#58C0E1"
                },{
                    value: 80,
                    color: "#AAD7D6"
                },{
                    value: 100,
                    color: "#FFF6B1"
                }],
                format: (value) => `${value}%`,
            },
            style: {
                stroke: { width: 1, color: "#333" },
                fill: { color: "rgba(255,255,255,0.4)", gradient: { colors: [{
                    value: 20,
                    color: "#376A9D"
                },{
                    value: 40,
                    color: "#2A95C0"
                },{
                    value: 60,
                    color: "#58C0E1"
                },{
                    value: 80,
                    color: "#AAD7D6"
                },{
                    value: 100,
                    color: "#FFF6B1"
                }], attribute: "Bob_Kuk"  } },
                point: { radius: 4  }
            },
            url: "/data/koncentracie_2021.geojson",
            filter: {
                type: "<>",
                attribute: "Bob_Kuk",
                from: 0,
                to: 100,
                min: 0,
                max: 100
            }
        },
        {
            id: 40,
            title: "Koncentrácie podľa výskytu záplav v posledných 20 rokoch",
            identificationTitle: "Kategória - Koncentrácie",
            displayInLayerTree: true,
            group: "Koncentrácie",
            visible: false,
            // style: {
            //     stroke: { width: 1, color: "#333" },
            //     fill: { color: "rgba(255,0,0,1)" },
            //     point: { radius: 4  }
            // },
            url: "/data/koncentracie_2021.geojson",
            legend: {
                values: [{
                    value: "Nie",
                    color: "#58C0E1"
                },{
                    value: "Áno",
                    color: "#CF6A8F"
                }]
            },
            style: {
                stroke: { width: 1, color: "#333" },
                fill: { color: "rgba(255,255,255,0.4)", categories: { colors: [{
                    value: "Nie",
                    color: "#58C0E1"
                },{
                    value: "Áno",
                    color: "#CF6A8F"
                }], attribute: "povodne"  } },
                point: { radius: 4  }
            }
            // filter: {
            //     attributes: ['Áno', 'Nie'],
            //     type: "=",
            //     attribute: "povodne",
            //     value: "Áno"
            // }
        },
        {
            id: 14,
            title: "Vzdialenosť koncentrácií k najbližšej zastávke železnice",
            identificationTitle: "Kategória - Koncentrácie",
            displayInLayerTree: true,
            group: "Koncentrácie",
            visible: false,
            legend: {
                values: [{
                    value: 2,
                    color: "#2A95C0"
                },{
                    value: 5,
                    color: "#AAD7D6"
                },{
                    value: 10,
                    color: "#FFF6B1"
                },{
                    value: 20,
                    color: "#EC9494"
                },{
                    value: 75,
                    color: "#CF6A8F"
                }],
                format: (value) => `${value} km`,
            },
            style: {
                stroke: { width: 1, color: "#333" },
                fill: { color: "rgba(255,255,255,0.4)", gradient: { colors: [{
                    value: 2,
                    color: "#2A95C0"
                },{
                    value: 5,
                    color: "#AAD7D6"
                },{
                    value: 10,
                    color: "#FFF6B1"
                },{
                    value: 20,
                    color: "#EC9494"
                },{
                    value: 75,
                    color: "#CF6A8F"
                }], attribute: "zeleznica"  } },
                point: { radius: 4  }
            },
            url: "/data/koncentracie_2021.geojson",
            filter: {
                type: "<>",
                attribute: "zeleznica",
                from: 0,
                to: 75,
                min: 0,
                units: "",
                max: 75
            }
        },
        {
            id: 15,
            title: "Vzdialenosť koncentrácií k najbližšej ambulancii všeobecného lekára",
            identificationTitle: "Kategória - Koncentrácie",
            displayInLayerTree: true,
            group: "Koncentrácie",
            visible: false,
            legend: {
                values: [{
                    value: 2,
                    color: "#2A95C0"
                },{
                    value: 5,
                    color: "#AAD7D6"
                },{
                    value: 10,
                    color: "#FFF6B1"
                },{
                    value: 20,
                    color: "#EC9494"
                },{
                    value: 28,
                    color: "#CF6A8F"
                }],
                format: (value) => `${value} km`,
            },
            style: {
                stroke: { width: 1, color: "#333" },
                fill: { color: "rgba(255,255,255,0.4)", gradient: { colors: [{
                    value: 2,
                    color: "#2A95C0"
                },{
                    value: 5,
                    color: "#AAD7D6"
                },{
                    value: 10,
                    color: "#FFF6B1"
                },{
                    value: 20,
                    color: "#EC9494"
                },{
                    value: 28,
                    color: "#CF6A8F"
                }], attribute: "lekar"  } },
                point: { radius: 4  }
            },
            url: "/data/koncentracie_2021.geojson",
            filter: {
                type: "<>",
                attribute: "lekar",
                from: 0,
                units: "",
                to: 28,
                min: 0,
                max: 28
            }
        },
        {
            id: 16,
            title: "Vzdialenosť koncentrácií k najbližšej navštevovanej materskej škole",
            identificationTitle: "Kategória - Koncentrácie",
            displayInLayerTree: true,
            group: "Koncentrácie",
            visible: false,
            legend: {
                values: [{
                    value: 2,
                    color: "#2A95C0"
                },{
                    value: 5,
                    color: "#AAD7D6"
                },{
                    value: 10,
                    color: "#FFF6B1"
                },{
                    value: 20,
                    color: "#EC9494"
                }],
                format: (value) => `${value} km`,
            },
            style: {
                stroke: { width: 1, color: "#333" },
                fill: { color: "rgba(255,255,255,0.4)", gradient: { colors: [{
                    value: 2,
                    color: "#2A95C0"
                },{
                    value: 5,
                    color: "#AAD7D6"
                },{
                    value: 10,
                    color: "#FFF6B1"
                },{
                    value: 20,
                    color: "#EC9494"
                }], attribute: "MŠ"  } },
                point: { radius: 4  }
            },
            url: "/data/koncentracie_2021.geojson",
            filter: {
                type: "<>",
                attribute: "MŠ",
                units: "",
                from: 0,
                to: 20,
                min: 0,
                max: 20
            }
        },
        {
            id: 17,
            title: "Vzdialenosť koncentrácií k najbližšej navštevovanej základnej škole 1-4",
            identificationTitle: "Kategória - Koncentrácie",
            displayInLayerTree: true,
            group: "Koncentrácie",
            visible: false,
            legend: {
                values: [{
                    value: 2,
                    color: "#2A95C0"
                },{
                    value: 5,
                    color: "#AAD7D6"
                },{
                    value: 10,
                    color: "#FFF6B1"
                },{
                    value: 20,
                    color: "#EC9494"
                }],
                format: (value) => `${value} km`,
            },
            style: {
                stroke: { width: 1, color: "#333" },
                fill: { color: "rgba(255,255,255,0.4)", gradient: { colors: [{
                    value: 2,
                    color: "#2A95C0"
                },{
                    value: 5,
                    color: "#AAD7D6"
                },{
                    value: 10,
                    color: "#FFF6B1"
                },{
                    value: 20,
                    color: "#EC9494"
                }], attribute: "1-4"  } },
                point: { radius: 4  }
            },
            url: "/data/koncentracie_2021.geojson",
            filter: {
                type: "<>",
                attribute: "1-4",
                from: 0,
                to: 20,
                units: "",
                min: 0,
                max: 20
            }
        },
        {
            id: 18,
            title: "Vzdialenosť koncentrácií k najbližšej navštevovanej základnej škole 1-9",
            identificationTitle: "Kategória - Koncentrácie",
            displayInLayerTree: true,
            group: "Koncentrácie",
            legend: {
                values: [{
                    value: 2,
                    color: "#2A95C0"
                },{
                    value: 5,
                    color: "#AAD7D6"
                },{
                    value: 10,
                    color: "#FFF6B1"
                },{
                    value: 22,
                    color: "#EC9494"
                }],
                format: (value) => `${value} km`,
            },
            visible: false,
            style: {
                stroke: { width: 1, color: "#333" },
                fill: { color: "rgba(255,255,255,0.4)", gradient: { colors: [{
                    value: 2,
                    color: "#2A95C0"
                },{
                    value: 5,
                    color: "#AAD7D6"
                },{
                    value: 10,
                    color: "#FFF6B1"
                },{
                    value: 22,
                    color: "#EC9494"
                }], attribute: "1-9"  } },
                point: { radius: 4  }
            },
            url: "/data/koncentracie_2021.geojson",
            filter: {
                type: "<>",
                attribute: "1-9",
                from: 0,
                to: 22,
                units: "",
                min: 0,
                max: 22
            }
        },
    ],
    view: {
        center: [19.2, 48.7],
        zoom: 8,
        minZoom: 7,
        maxZoom: 11,
        projection: "EPSG:3857"
    }
}



const map = (state = initState, action) => {
    switch (action.type) {
        case 'SET_BASELAYER_VISIBILITY':
            return {
                ...state,
                baseLayers: state.baseLayers.map(bl => ({
                    ...bl,
                    visible: bl.id === action.id ? true : false
                }))
            }
        case 'SET_LAYER_FILTER':
            return {
                ...state,
                interactiveLayers: state.interactiveLayers.map(l => {
                    if(l.id === action.id){
                        l.filter = { ...l.filter, ...action.filter }
                    }
                    return l;
                })
            }
        case 'SET_LAYER_VISIBILITY':
            const newState = state;

            
            // let group = "";
            // if(action.layerType === "interactive"){
            //     group = state.interactiveLayers.find(x => x.id === action.id).group;
            // }

            const mutuallyInclusiveGroups = ["Koncentrácie", "Obce"]


            const handleVisibility = l => ({
                ...l,
                visible: l.id === action.id ? !l.visible : (mutuallyInclusiveGroups.includes(l.group) ? false : l.visible)
            })

            // const handleVisibility = l => ({
            //     ...l,
            //     visible: l.id === action.id ? !l.visible : false
            // })
            if(action.layerType === "interactive"){
                newState.interactiveLayers = state.interactiveLayers.map(handleVisibility)
            } else {
                newState.layers = state.layers.map(handleVisibility)
            }
            return newState;
        case 'SET_LAYER_PROPERTY':
            return {
                ...state,
                layers: state.layers.map(l => {
                    if(l.id === action.id){
                        l[action.property] = action.value
                    }
                    return l;
                })
            }
        case 'SET_LAYERS':
            return {
                ...state,
                layers: action.layers
            }
        case 'ADD_LAYER':
            return {
                ...state,
                layers: [
                    ...state.layers, 
                    action.layer
                ]
            }
        case 'REMOVE_LAYER':
            return {
                ...state,
                layers: state.layers.filter(l => l.id !== action.id)
            }
        default:
            return state
    }
}

export default map;