import { useEffect, useCallback, useMemo } from "react"
import ol_vector_Source from "ol/source/Vector"
import ol_format_GeoJSON from "ol/format/GeoJSON"
import ol_layer_VectorImage from 'ol/layer/VectorImage';
import { useSelector, useDispatch } from "react-redux";
import {Fill, Stroke, Style} from 'ol/style';
import { 
    MUNICIPALITY_ATTR_TITLE, 
    MUNICIPALITY_ATTR_ROMANI_POPULATION, 
    MUNICIPALITY_ATTR_POPULATION,
    MUNICIPALITY_ATTR_TSP,
    MUNICIPALITY_ATTR_KC,
    MUNICIPALITY_ATTR_MOPS,
    MUNICIPALITY_ATTR_ASSISTENTS,
    MUNICIPALITY_ATTR_ZK,
    MUNICIPALITY_ATTR_MS_CONSTRUCTIONS,
    MUNICIPALITY_ATTR_KC_CONSTRUCTIONS,
    MUNICIPALITY_ATTR_WATER,
    MUNICIPALITY_ATTR_SEWERAGE,
    GRADIENT_WATER,
    GRADIENT_SEWERAGE,
    GRADIENT_POPULATION
} from "../constants";
import { useMap } from "../_core/hooks/useMap";

const style = new Style({
    stroke: new Stroke({
        color: '#777777',
        width: 1
    }),
    fill: new Fill({
        color: '#f00'
    })
})   

const defaultStyle = new Style({
    stroke: new Stroke({
      color: '#777777',
      width: 1
    }),
  });


export const getConstuctions = (feature) => {

    const constructions = [];

    if(feature.get(MUNICIPALITY_ATTR_MS_CONSTRUCTIONS) && feature.get(MUNICIPALITY_ATTR_MS_CONSTRUCTIONS) !== "nie"){
        constructions.push("Materská škola")
    }
    if(feature.get(MUNICIPALITY_ATTR_KC_CONSTRUCTIONS) && feature.get(MUNICIPALITY_ATTR_KC_CONSTRUCTIONS) !== "nie"){
        constructions.push("Komunitné centrum")
    }
    if(feature.get(MUNICIPALITY_ATTR_WATER) && feature.get(MUNICIPALITY_ATTR_WATER) !== "nie"){
        constructions.push("Pitná voda")
    }
    if(feature.get(MUNICIPALITY_ATTR_SEWERAGE) && feature.get(MUNICIPALITY_ATTR_SEWERAGE) !== "nie"){
        constructions.push("Kanalizácia")
    }
    return constructions.join(", ")
}

export const getProjects = (feature) => {

    const projects = [];

    if(feature.get(MUNICIPALITY_ATTR_TSP) && feature.get(MUNICIPALITY_ATTR_TSP) !== "nie"){
        projects.push("TSP")
    }
    if(feature.get(MUNICIPALITY_ATTR_KC) && feature.get(MUNICIPALITY_ATTR_KC) !== "nie"){
        projects.push("KC")
    }
    if(feature.get(MUNICIPALITY_ATTR_MOPS) && feature.get(MUNICIPALITY_ATTR_MOPS) !== "nie"){
        projects.push("MOPS")
    }
    if(feature.get(MUNICIPALITY_ATTR_ASSISTENTS) && feature.get(MUNICIPALITY_ATTR_ASSISTENTS) !== "nie"){
        projects.push("Asistenti")
    }
    if(feature.get(MUNICIPALITY_ATTR_ZK) && feature.get(MUNICIPALITY_ATTR_ZK) !== "nie"){
        projects.push("ZK")
    }
    
    
    return projects.join(", ")
}

const getScale = (type) => {
    if(type === "water")
        return GRADIENT_WATER
    if(type === "sewerage"){
        return GRADIENT_SEWERAGE
    }
    return GRADIENT_POPULATION
}

const generateGradientStyle = (type, value) => {

    const scale = getScale(type)
    
    const getColor = () => {
        if(!value) return "#fff"
        const item = scale.find(x => value <= x.value)
        return item ? item.color : "#fff";
    }

    return new Style({
        fill: new Fill({
          color: getColor(value)
        }),
        stroke: new Stroke({
          color: "#777777",
          width: 1
        }),
      });
}

const MunicipalitiesLayer = ({
    url
}) => {
    
    const map = useMap()

    const { visible, filter, activeFilter, search, gradients, mode } = useSelector(state => state.municipalityLayer)
    const dispatch = useDispatch()

    const getGradientStyle = useCallback((feature) => {
        const activeGradient = gradients.find(g => g.active);
        
        if(feature.get(activeGradient.attr) === null)
            return null
        
        if(activeGradient)
            return generateGradientStyle(activeGradient.id, feature.get(activeGradient.attr))
        return defaultStyle;
    }, [gradients])

    const setGradientStyle = useCallback((feature) => {
        const s = getGradientStyle(feature)
        feature.setStyle(s)
    }, [getGradientStyle])

    const applyFilter = useCallback((feature) => {
        
        if(!activeFilter){
            feature.setStyle(defaultStyle);
            return;
        }

        const hasActiveFilter = filter.find(f => f.active === true)

        if(!hasActiveFilter && feature.get(MUNICIPALITY_ATTR_ROMANI_POPULATION)){
            feature.setStyle(style)
            return;
        }

        const activeFilters = filter.filter(f => f.active)
        const matchedFilters = filter.filter(f => {
            if( f.active === true && 
                feature.get(f.attr) && 
                feature.get(f.attr) !== f.value
            ){
                return true;
            }
            return false
        })

        if(activeFilters.length > 0 && activeFilters.length === matchedFilters.length){
            feature.setStyle(style)
            return;
        }

        feature.setStyle(defaultStyle)
       
    }, [filter, activeFilter])
    
    const olLayer = useMemo(() => new ol_layer_VectorImage({
        imageRatio: 2,
        visible,
        identificationTitle: "Kategória - Obce (plošné)",
        source: new ol_vector_Source({
            url,
            format: new ol_format_GeoJSON()
        }),
        style: applyFilter
        // eslint-disable-next-line
    }), [])
     
    useEffect(() => {
        
        const features = olLayer.getSource().getFeatures();
        const results = []

        const adaptString = (str) => {
            return str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        }

        let count = 0;

        features.forEach((feature) => {
            if(count > 8) return;
            if(adaptString(feature.get(MUNICIPALITY_ATTR_TITLE)).startsWith(search) && feature.get(MUNICIPALITY_ATTR_POPULATION)){
                results.push(feature.clone())
                count++
            }
        })

        const setSearchResults = (r) => {
            dispatch({type: "SET_SEARCH_RESULTS", results: r})
        }

        setSearchResults(results)

    }, [search, olLayer, dispatch])

    useEffect(() => {
        const features = olLayer.getSource().getFeatures();
        
        if(mode === "gradient")
            features.forEach(setGradientStyle)
        else
            features.forEach(applyFilter)
    }, [olLayer, setGradientStyle, applyFilter, activeFilter, mode])
    
    useEffect(() => {
        olLayer && olLayer.setVisible(visible)
    }, [olLayer, visible])

    useEffect(() => {
        map.addLayer(olLayer)
        return () => {
            map.removeLayer(olLayer)
        }
    }, [map, olLayer])
    
    return null
}

export default MunicipalitiesLayer