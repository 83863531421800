import { Collapse, withStyles, Paper, TextField, MenuItem } from "@material-ui/core";
import React, { useCallback } from "react"
import Slider from '@material-ui/core/Slider';
import { useDispatch } from "react-redux";

function valuetext(value, units = "%") {
    return `${value}${units}`;
}

const Filter = ({
    attributes,
    type,
    value,
    from, 
    min,
    max,
    units,
    to, 
    id,
    classes,
    colapsed
}) => {

    const dispatch = useDispatch()

    const handleChange = useCallback((value) => {
        dispatch({type: "SET_LAYER_FILTER", id, filter: { from: value[0], to: value[1] }})
    }, [id, dispatch])

    const handleChangeValue = useCallback((value) => {
        dispatch({type: "SET_LAYER_FILTER", id, filter: { value: value }})
    }, [id, dispatch])

    return (
        <Collapse in={colapsed}>
            <Paper classes={{root: classes.paper}}>
                {(attributes && attributes.length && type === "=") && (
                    <TextField 
                        classes={{root: classes.select}}
                        variant="outlined"
                        size="small"
                        SelectProps={{displayEmpty: true}}
                        onChange={(e) => handleChangeValue(e.target.value)}
                        value={value}
                        fullWidth
                        select
                    >
                        <MenuItem value="">-- Vyberte hodnotu --</MenuItem>
                        {
                            attributes.map((attribute, idx) => (
                                <MenuItem value={attribute}>{attribute}</MenuItem>
                            ))
                        }
                    </TextField>
                )}
                {(type === "<>") &&
                    <Slider
                        step={1}
                        classes={{root: classes.slider, valueLabel: classes.sliderLabel}}
                        defaultValue={[from, to]}
                        max={max}
                        min={min}
                        onChangeCommitted={(e, newValue) => handleChange(newValue)}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                        valueLabelFormat={(value) => valuetext(value, units)}
                    />
                }
            </Paper>
        </Collapse>
    )
}

const styles = (theme) => ({
    paper: {
        marginLeft: 16,
        marginTop: theme.spacing(1),
        marginRight: 16,
        marginBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    filterText: {
        fontWeight: 600
    },
    sliderLabel: {
        "& > span  > span": {
            color: theme.palette.common.white
        }
    },
    select: {
        marginTop: 8,
        marginBottom: 16
    }
})

export default withStyles(styles)(Filter);