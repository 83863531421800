import {Stroke, Style, Fill, Circle, Icon} from 'ol/style';
import { getRadiusSymbol } from '../Marker';

export const getDefaultStyle = ({stroke, fill, point}) => new Style({
    stroke: stroke && new Stroke({
        color: stroke.color,
        width: stroke.width
    }),
    fill: fill && new Fill({
        color: fill.color
    }),
    image:  point && new Icon({
        src: 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(getRadiusSymbol({
            size: point.radius * 2.5, 
            color: fill && fill.color, 
            strokeWidth: stroke && stroke.width, 
            strokeColor: stroke && stroke.color
        }))))
    }),
    // image: point && new Circle({
    //     fill: fill && new Fill({
    //         color: fill.color
    //     }),
    //     stroke: stroke && new Stroke({
    //         color: stroke.color,
    //         width: stroke.width
    //     }),
    //     radius: point.radius
    // })
})   

export const greenPointStyle = new Style({
    image: new Circle({
        fill: new Fill({
            color: 'rgba(255,255,255,0.7)'
        }),
        stroke: new Stroke({
            color: '#0000FF',
            width: 2
        }),
        radius: 3
    })
})   

export const bluePointStyle = new Style({
    image: new Circle({
        fill: new Fill({
            color: 'rgba(255,255,255,0.7)'
        }),
        stroke: new Stroke({
            color: '#00FF00',
            width: 2
        }),
        radius: 3
    })
})   